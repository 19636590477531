import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../modules/auth/hooks/useAuth'
import { APP_ROUTES, } from '../../routing/routes'
import IconButton from '../../components/IconButton/IconButton'
import { useDispatch } from 'react-redux'
import { updateForceChangePassword } from '../../modules/auth/features/auth.slice'
import ChangePassword from '../../components/ChangePassword/ChangePassword'

const ForceChangePassword = () => {
  const dispatch = useDispatch()
  const { currentUser, isForceChangePassword } = useAuth()
  const navigate = useNavigate()
  const handleSubmit = () => {
    dispatch(updateForceChangePassword({ force_password_change: 0 }))
  }
  const logout = () => {
    navigate(APP_ROUTES.LOGOUT);
  }
  useEffect(() => {
    if (!isForceChangePassword)
      navigate(currentUser?.role.home as string);
  }, [isForceChangePassword])
  return (
    <div className='d-flex flex-column'>
      <div className='d-flex justify-content-between align-items-center m-2'>
        <h3 className='m-0'>Please change your password</h3>
        <div className='d-flex align-items-center' onClick={logout}>
          <span className='fw-bolder text-primary cursor-pointer'>Logout</span>
          <IconButton icon='exit-right' title='Logout' className='btn-icon-primary' onClick={logout} />
        </div>
      </div>
      <ChangePassword afterSubmit={handleSubmit} />
    </div>
  )
}

export default ForceChangePassword