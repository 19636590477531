
import { Button, ButtonProps, Spinner } from 'react-bootstrap'
interface ILoadingButton extends ButtonProps {
    loading?: boolean;
}
const LoadingButton = ({ loading, children, disabled, ...rest }: ILoadingButton) => {
    return (
        <Button variant="primary" className='z-index-0' disabled={disabled || loading} {...rest}>
            {loading ? <Spinner animation="border" size="sm" /> : children}
        </Button>
    )
}

export default LoadingButton