import SelectModeType from '../../components/SelectModeType/SelectModeType'
import { FormProvider, useForm } from 'react-hook-form'
import { Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { setModeType } from '../../modules/auth/features/auth.slice'
import IconButton from '../../components/IconButton/IconButton'
interface IAuctionMode {
    mode_type: 'participate' | 'conduct'
}
const AuctionMode = () => {
    const { $t } = useIntl()
    const dispatch = useDispatch()
    const methods = useForm<IAuctionMode>()
    const onSubmit = (data: IAuctionMode) => {
        dispatch(setModeType({ mode: data.mode_type }))
    }
    return (
        <div className='d-flex w-100 h-100 flex-center'>
            <form onSubmit={methods.handleSubmit(onSubmit)} >

                <h2 className="fw-bold d-flex flex-center text-gray-900 mb-10" style={{ fontSize: '3.625rem' }}>
                    {$t({ id: 'AUCTION_MODE.PAGE.TITLE' })}
                </h2>

                <FormProvider {...methods}>
                    <SelectModeType />
                </FormProvider>


                <div className="d-flex flex-center" style={{ marginTop: 150 }}>
                    <div className="p-4 bg-primary rounded-circle symbol">
                        <IconButton iconClassName='text-white' iconSize={48} icon='arrow-right' iconType='solid' type='submit' ></IconButton>
                    </div>
                </div>

            </form>
        </div>
    )
}

export default AuctionMode