
import { Route, Routes } from 'react-router-dom'
import { Suspense, lazy } from 'react';

const Error404 = lazy(() => import('../../modules/errors/components/Error404'));
const Error500 = lazy(() => import('../../modules/errors/components/Error500'));
const Error401 = lazy(() => import('../../modules/errors/components/Error401'));
import { ErrorsLayout } from './ErrorsLayout'
import SplashScreen from '../../components/SplashScreen/SplashScreen';

const ErrorsPage = () => (
  <Suspense fallback={<SplashScreen />}>
    <Routes>
      <Route element={<ErrorsLayout />}>
        <Route path='404' element={<Error404 />} />
        <Route path='500' element={<Error500 />} />
        <Route path='401' element={<Error401 />} />
        <Route index element={<Error404 />} />
      </Route>
    </Routes>
  </Suspense>
)

export { ErrorsPage }
