import { IModules } from "../pages/moduleCreator/models/Modules.interfaces";

export const mockConductModeRoutes: {
    status: boolean;
    message?: string;
    data: {
        id: number;
        user_id: string;
        role: string;
        access: string;
        permissions: IModules[];
        home: string
    };
} = {
    data: {
        id: 30001,
        user_id: "142323",
        role: "Vendor",
        access: "v",
        home: "/dashboard",
        permissions: [
            {
                id: 1,
                type: "header",
                path: undefined,
                title: "NAVIGATION.CONDUCT.MAIN",
                folder: undefined,
                element: undefined,
                parentId: undefined,
                generateCRUD: true,
                level: 1,
                menu_order: undefined,
                actions: [],
                sidebarOptions: {
                    module_id: 1,
                    hidden: true,
                    icon: "document",
                    iconType: "duotone"
                }
            },
            {
                id: 2,
                type: "item",
                path: "dashboard",
                title: "NAVIGATION.CONDUCT.HOME",
                folder: "adminDashboard",
                element: "AdminDashboard",
                parentId: 1,
                generateCRUD: false,
                level: 2,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 2,
                    hidden: false,
                    icon: "home",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Dashboard",
                        key: "dashboard",
                        value: true,
                        type: "global",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Dashboard Event Calendar",
                        key: "dashboard-event-calendar",
                        value: true,
                        type: "global",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Dashboard create event",
                        key: "dashboard-create-event",
                        value: true,
                        type: "global",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "View last 5 events",
                        key: "view-last-5-event",
                        value: true,
                        type: "global",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "View Live Events",
                        key: "view-live-events",
                        value: true,
                        type: "global",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "View Top 5 Draft Events",
                        key: "view-top-5-draft-events",
                        value: true,
                        type: "global",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Dashboard last login",
                        key: "dashboard-last-login",
                        value: true,
                        type: "global",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                ]
            },

            // Masters
            {
                id: 3,
                type: "list",
                path: "master",
                title: "NAVIGATION.CONDUCT.MASTERS",
                folder: undefined,
                element: undefined,
                parentId: 1,
                generateCRUD: true,
                level: 2,
                menu_order: undefined,
                actions: [],
                sidebarOptions: {
                    module_id: 3,
                    hidden: false,
                    icon: "cube-2",
                    iconType: "outline"
                }
            },
            {
                id: 4,
                type: "item",
                path: "users",
                title: "NAVIGATION.CONDUCT.USERS",
                folder: "users",
                element: "Users",
                parentId: 3,
                generateCRUD: false,
                level: 3,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 4,
                    hidden: false,
                    icon: "people",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Create",
                        key: "create",
                        value: true,
                        type: "global",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Read",
                        key: "read",
                        value: false,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Update",
                        key: "update",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Delete",
                        key: "delete",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    }

                ]
            },
            {
                id: 5,
                type: "item",
                path: "department",
                title: "NAVIGATION.CONDUCT.DEPARTMENT",
                folder: "department",
                element: "Department",
                parentId: 3,
                generateCRUD: false,
                level: 3,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 5,
                    hidden: false,
                    icon: "briefcase",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Create",
                        key: "create",
                        value: true,
                        type: "global",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Read",
                        key: "read",
                        value: false,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Update",
                        key: "update",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Delete",
                        key: "delete",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    }

                ]
            },
            {
                id: 6,
                type: "item",
                path: "products",
                title: "NAVIGATION.CONDUCT.PRODUCT",
                folder: "product",
                element: "Product",
                parentId: 3,
                generateCRUD: false,
                level: 3,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 6,
                    hidden: false,
                    icon: "parcel",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Create",
                        key: "create",
                        value: true,
                        type: "global",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Read",
                        key: "read",
                        value: false,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Update",
                        key: "update",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Delete",
                        key: "delete",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    }

                ]
            },
            {
                id: 7,
                type: "item",
                path: "unit",
                title: "NAVIGATION.CONDUCT.UNIT",
                folder: "unit",
                element: "Unit",
                parentId: 3,
                generateCRUD: false,
                level: 3,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 7,
                    hidden: false,
                    icon: "bill",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Create",
                        key: "create",
                        value: true,
                        type: "global",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Read",
                        key: "read",
                        value: false,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Update",
                        key: "update",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Delete",
                        key: "delete",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    }

                ]
            },
            {
                id: 8,
                type: "item",
                path: "terms-And-Conditions",
                title: "NAVIGATION.CONDUCT.TERMS_AND_CONDITION",
                folder: "termsAndCondition",
                element: "TermsAndCondition",
                parentId: 3,
                generateCRUD: false,
                level: 3,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 8,
                    hidden: false,
                    icon: "message-question",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Create",
                        key: "create",
                        value: true,
                        type: "global",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Read",
                        key: "read",
                        value: false,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Update",
                        key: "update",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Delete",
                        key: "delete",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    }

                ]
            },
            {
                id: 40,
                type: "item",
                path: "company",
                title: "NAVIGATION.CONDUCT.COMPANY",
                folder: "company",
                element: "Company",
                parentId: 3,
                generateCRUD: false,
                level: 3,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 40,
                    hidden: false,
                    icon: "graph-3",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Create",
                        key: "create",
                        value: true,
                        type: "global",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Read",
                        key: "read",
                        value: false,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Update",
                        key: "update",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Delete",
                        key: "delete",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    }

                ]
            },
            // End Masters
            {
                id: 9,
                type: "item",
                path: "suppliers",
                title: "NAVIGATION.CONDUCT.SUPPLIERS",
                folder: "suppliers",
                element: "Suppliers",
                parentId: 1,
                generateCRUD: false,
                level: 2,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 9,
                    hidden: false,
                    icon: "profile-user",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Create",
                        key: "create",
                        value: true,
                        type: "global",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Read",
                        key: "read",
                        value: false,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Update",
                        key: "update",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    },
                    {
                        title: "Delete",
                        key: "delete",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined
                    }

                ]
            },
            // Events
            {
                id: 10,
                type: "list",
                path: "events",
                title: "NAVIGATION.CONDUCT.EVENTS",
                folder: undefined,
                element: undefined,
                parentId: 1,
                generateCRUD: true,
                level: 2,
                menu_order: undefined,
                actions: [],
                sidebarOptions: {
                    module_id: 10,
                    hidden: false,
                    icon: "abstract-22",
                    iconType: "outline"
                }
            },
            // Event tender
            {
                id: 11,
                type: "list",
                path: "tender",
                title: "NAVIGATION.CONDUCT.TENDER_RFQ",
                folder: undefined,
                element: undefined,
                parentId: 10,
                generateCRUD: false,
                level: 3,
                menu_order: undefined,
                actions: [],
                sidebarOptions: {
                    module_id: 11,
                    hidden: false,
                    icon: "cheque",
                    iconType: "outline"
                },
            },
            {
                id: 12,
                type: "item",
                path: "tender/new",
                title: "NAVIGATION.CONDUCT.NEW",
                folder: "tender",
                element: "TenderNew",
                parentId: 11,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 12,
                    hidden: false,
                    icon: "cheque",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "New Tender",
                        key: "new-tender",
                        value: true,
                        type: "global",
                        isDefault: false,
                        element: undefined,
                        path: undefined
                    }

                ]
            },
            {
                id: 13,
                type: "item",
                path: "tender/draft",
                title: "NAVIGATION.CONDUCT.DRAFT",
                folder: "tender",
                element: "TenderDraft",
                parentId: 11,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 13,
                    hidden: false,
                    icon: "send",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Complete Tender",
                        key: "complete-tender",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: undefined,
                        path: undefined
                    },
                ]
            },
            {
                id: 14,
                type: "item",
                path: "tender/approve",
                title: "NAVIGATION.CONDUCT.APPROVE",
                folder: "tender",
                element: "TenderApprove",
                parentId: 11,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 14,
                    hidden: false,
                    icon: "verify",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Approve Tender",
                        key: "approve-tender",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: undefined,
                        path: undefined,
                        icon: {
                            icon: 'check-circle',
                            iconType: 'outline',
                            iconClassName: 'text-primary'
                        }
                    },
                    {
                        title: "Reject Tender",
                        key: "reject-tender",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: undefined,
                        path: undefined,
                        icon: {
                            icon: 'cross-circle',
                            iconType: 'outline',
                            iconClassName: 'text-danger'
                        }
                    },
                    {
                        title: "View Tender",
                        key: "read",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined,

                    },
                    {
                        title: "Copy Tender",
                        key: "copy-tender",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: undefined,
                        path: undefined,
                        icon: {
                            icon: 'copy',
                            iconType: 'outline',
                            iconClassName: 'text-warning'
                        }
                    },
                    {
                        title: "View quotation",
                        key: 'view-quotation',
                        value: true,
                        isDefault: false,
                        element: undefined,
                        path: undefined,
                        type: 'inline',
                        icon: {
                            icon: 'cheque',
                            iconClassName: 'text-secondary',
                            iconType: 'outline'
                        }
                    },
                ]
            },
            // Reverse Auction
            {
                id: 15,
                type: "list",
                path: "reverse-auction",
                title: "NAVIGATION.CONDUCT.REVERSE_AUCTION",
                folder: undefined,
                element: undefined,
                parentId: 10,
                generateCRUD: false,
                level: 3,
                menu_order: undefined,
                actions: [],
                sidebarOptions: {
                    module_id: 15,
                    hidden: false,
                    icon: "abstract",
                    iconType: "outline"
                },
            },
            {
                id: 16,
                type: "item",
                path: "reverse-auction/new",
                title: "NAVIGATION.CONDUCT.NEW",
                folder: "reverseAuction",
                element: "ReverseAuctionNew",
                parentId: 15,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 16,
                    hidden: false,
                    icon: "cheque",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "NAVIGATION.CONDUCT.NEW",
                        key: "reverse-auction-proceed",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: 'ReverseAuctionTabs',
                        path: '/reverse-auction/tab'
                    },
                    {
                        title: "NAVIGATION.CONDUCT.NEW",
                        key: "reverse-auction-tender-list",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: 'ReverseAuctionTenderList',
                        path: '/reverse-auction/tender-list'
                    }

                ]
            },
            {
                id: 17,
                type: "item",
                path: "reverse-auction/draft",
                title: "NAVIGATION.CONDUCT.DRAFT",
                folder: "reverseAuction",
                element: "ReverseAuctionDraft",
                parentId: 15,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 17,
                    hidden: false,
                    icon: "send",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Complete Reverse Aucton",
                        key: "complete-reverse-auction",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: undefined,
                        path: undefined
                    },]
            },
            {
                id: 18,
                type: "item",
                path: "reverse-auction/approve",
                title: "NAVIGATION.CONDUCT.APPROVE",
                folder: "reverseAuctionApprove",
                element: "ReverseAuctionApprove",
                parentId: 15,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 18,
                    hidden: false,
                    icon: "verify",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Approve Reverse Auction",
                        key: "approve-reverse-auction",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: undefined,
                        path: undefined,
                        icon: {
                            icon: 'check-circle',
                            iconType: 'outline',
                            iconClassName: 'text-primary'
                        }
                    },
                    {
                        title: "Reject Reverse Auction",
                        key: "reject-reverse-auction",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: undefined,
                        path: undefined,
                        icon: {
                            icon: 'cross-circle',
                            iconType: 'outline',
                            iconClassName: 'text-danger'
                        }
                    },
                    {
                        title: "View Tender",
                        key: "read",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined,

                    },
                    {
                        title: "Copy Reverse Auction",
                        key: "copy-reverse-auction",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: undefined,
                        path: undefined,
                        icon: {
                            icon: 'copy',
                            iconType: 'outline',
                            iconClassName: 'text-warning'
                        }
                    },
                    {
                        title: "View Reverse Auction",
                        key: 'view-reverse-auction',
                        value: true,
                        isDefault: false,
                        element: undefined,
                        path: undefined,
                        type: 'inline',
                        icon: {
                            icon: 'cheque',
                            iconClassName: 'text-secondary',
                            iconType: 'outline'
                        }
                    },

                ]
            },
            {
                id: 35,
                type: "item",
                path: "/reverse-auction/live",
                title: "NAVIGATION.CONDUCT.LIVE_REVERSE_AUCTION",
                folder: "conductLiveReverseAuction",
                element: "ConductLiveReverseAuction",
                parentId: 15,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 18,
                    hidden: false,
                    icon: "verify",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Conduct mode Live Reverse Auction",
                        key: "conduct-live-reverse-auction",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: undefined,
                        path: undefined,
                        icon: {
                            icon: 'check-circle',
                            iconType: 'outline',
                            iconClassName: 'text-primary'
                        }
                    },
                    {
                        title: "Conduct mode chat",
                        key: "conduct-mode-chat",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: undefined,
                        path: undefined,
                        icon: {
                            icon: 'cross-circle',
                            iconType: 'outline',
                            iconClassName: 'text-danger'
                        }
                    },
                    {
                        title: "Conduct mode graph view",
                        key: "conduct-mode-graph-view",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined,

                    },


                ]
            },
            // Forward Auction
            {
                id: 19,
                type: "list",
                path: "forward-auction",
                title: "NAVIGATION.CONDUCT.FORWARD_AUCTION",
                folder: undefined,
                element: undefined,
                parentId: 10,
                generateCRUD: false,
                level: 3,
                menu_order: undefined,
                actions: [],
                sidebarOptions: {
                    module_id: 19,
                    hidden: false,
                    icon: "abstract-44",
                    iconType: "outline"
                },
            },
            {
                id: 20,
                type: "item",
                path: "forward-auction/new",
                title: "NAVIGATION.CONDUCT.NEW",
                folder: "ForwardAuction",
                element: "ForwardAuctionNew",
                parentId: 19,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 20,
                    hidden: false,
                    icon: "cheque",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "NAVIGATION.CONDUCT.NEW",
                        key: "forward-auction-proceed",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: 'ForwardAuctionTabs',
                        path: '/forward-auction/tab'
                    },
                    {
                        title: "NAVIGATION.CONDUCT.NEW",
                        key: "forward-auction-tender-list",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: 'ForwardAuctionTenderList',
                        path: '/forward-auction/tender-list'
                    }

                ]
            },
            {
                id: 21,
                type: "item",
                path: "forward-auction/draft",
                title: "NAVIGATION.CONDUCT.DRAFT",
                folder: "ForwardAuction",
                element: "ForwardAuctionDraft",
                parentId: 19,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 21,
                    hidden: false,
                    icon: "send",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Complete Forward Auction",
                        key: "complete-forward-auction",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: undefined,
                        path: undefined
                    }]
            },
            {
                id: 22,
                type: "item",
                path: "forward-auction/approve",
                title: "NAVIGATION.CONDUCT.APPROVE",
                folder: "ForwardAuctionApprove",
                element: "ForwardAuctionApprove",
                parentId: 19,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 22,
                    hidden: false,
                    icon: "verify",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Approve Forward Auction",
                        key: "approve-forward-auction",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: undefined,
                        path: undefined,
                        icon: {
                            icon: 'check-circle',
                            iconType: 'outline',
                            iconClassName: 'text-primary'
                        }
                    },
                    {
                        title: "Reject Forward Auction",
                        key: "reject-forward-auction",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: undefined,
                        path: undefined,
                        icon: {
                            icon: 'cross-circle',
                            iconType: 'outline',
                            iconClassName: 'text-danger'
                        }
                    },
                    {
                        title: "View Tender",
                        key: "read",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined,

                    },
                    {
                        title: "Copy Forward Auction",
                        key: "copy-forward-auction",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: undefined,
                        path: undefined,
                        icon: {
                            icon: 'copy',
                            iconType: 'outline',
                            iconClassName: 'text-warning'
                        }
                    },
                    {
                        title: "View Forward Auction",
                        key: 'view-forward-auction',
                        value: true,
                        isDefault: false,
                        element: undefined,
                        path: undefined,
                        type: 'inline',
                        icon: {
                            icon: 'cheque',
                            iconClassName: 'text-secondary',
                            iconType: 'outline'
                        }
                    },

                ]
            },
            {
                id: 36,
                type: "item",
                path: "/forward-auction/live",
                title: "NAVIGATION.CONDUCT.LIVE_FORWARD_AUCTION",
                folder: "conductLiveForwardAuction",
                element: "ConductLiveForwardAuction",
                parentId: 19,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 36,
                    hidden: false,
                    icon: "verify",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Conduct mode Live Forward Auction",
                        key: "conduct-live-forward-auction",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: undefined,
                        path: undefined,
                        icon: {
                            icon: 'check-circle',
                            iconType: 'outline',
                            iconClassName: 'text-primary'
                        }
                    },
                    {
                        title: "Conduct mode chat",
                        key: "conduct-mode-chat",
                        value: true,
                        type: "inline",
                        isDefault: false,
                        element: undefined,
                        path: undefined,
                        icon: {
                            icon: 'cross-circle',
                            iconType: 'outline',
                            iconClassName: 'text-danger'
                        }
                    },
                    {
                        title: "Conduct mode graph view",
                        key: "conduct-mode-graph-view",
                        value: true,
                        type: "inline",
                        isDefault: true,
                        element: undefined,
                        path: undefined,

                    },


                ]
            },
            // Equipment Listing
            {
                id: 23,
                type: "list",
                path: "equipment-listing",
                title: "NAVIGATION.CONDUCT.EQUIPMENT_LISTING",
                folder: undefined,
                element: undefined,
                parentId: 10,
                generateCRUD: false,
                level: 3,
                menu_order: undefined,
                actions: [],
                sidebarOptions: {
                    module_id: 23,
                    hidden: false,
                    icon: "setting-3",
                    iconType: "outline"
                },
            },
            {
                id: 24,
                type: "item",
                path: "equipment-listing/new",
                title: "NAVIGATION.CONDUCT.NEW",
                folder: "equipmentListing",
                element: "EquipmentListingNew",
                parentId: 23,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 24,
                    hidden: false,
                    icon: "add-item",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "New Reverse Auction",
                        key: "new-equipment-listing",
                        value: true,
                        type: "global",
                        isDefault: false,
                        element: undefined,
                        path: undefined
                    }

                ]
            },
            {
                id: 25,
                type: "item",
                path: "equipment-listing/draft",
                title: "NAVIGATION.CONDUCT.DRAFT",
                folder: "equipmentListing",
                element: "EquipmentListingDraft",
                parentId: 23,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 25,
                    hidden: false,
                    icon: "pencil",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Draft Reverse Auction",
                        key: "draft-equipment-listing",
                        value: true,
                        type: "global",
                        isDefault: false,
                        element: undefined,
                        path: undefined
                    }]
            },
            {
                id: 26,
                type: "item",
                path: "equipment-listing/publish",
                title: "NAVIGATION.CONDUCT.PUBLISH",
                folder: "equipmentListing",
                element: "EquipmentListingPublish",
                parentId: 23,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 26,
                    hidden: false,
                    icon: "copy-success",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Approve Reverse Auction",
                        key: "approve-equipment-listing",
                        value: true,
                        type: "global",
                        isDefault: false,
                        element: undefined,
                        path: undefined
                    }
                ]
            },
            // Reports
            {
                id: 27,
                type: "list",
                path: "reports",
                title: "NAVIGATION.CONDUCT.REPORTS",
                folder: undefined,
                element: undefined,
                parentId: 1,
                generateCRUD: true,
                level: 2,
                menu_order: undefined,
                actions: [],
                sidebarOptions: {
                    module_id: 27,
                    hidden: false,
                    icon: "tablet-book",
                    iconType: "outline"
                }
            },
            {
                id: 28,
                type: "item",
                path: "reports/tender",
                title: "NAVIGATION.CONDUCT.TENDER_REPORTS",
                folder: "reports",
                element: "TenderReports",
                parentId: 27,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 28,
                    hidden: false,
                    icon: "document",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Tender Reports",
                        key: "tender-reports",
                        value: true,
                        type: "global",
                        isDefault: false,
                        element: undefined,
                        path: undefined,
                    }
                ]
            },
            {
                id: 29,
                type: "item",
                path: "reports/reverse-auction",
                title: "NAVIGATION.CONDUCT.REVERSE_AUCTION_REPORTS",
                folder: "reports",
                element: "ReverseAuctionReports",
                parentId: 27,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 29,
                    hidden: false,
                    icon: "update-file",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Reverse Auction Reports",
                        key: "reverse-auction-reports",
                        value: true,
                        type: "global",
                        isDefault: false,
                        element: undefined,
                        path: undefined
                    }
                ]
            },
            {
                id: 30,
                type: "item",
                path: "reports/forward-auction",
                title: "NAVIGATION.CONDUCT.FORWARD_AUCTION_REPORTS",
                folder: "reports",
                element: "ForwardAuctionReports",
                parentId: 27,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 30,
                    hidden: false,
                    icon: "file-right",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Forward Auction Reports",
                        key: "forward-auction-reports",
                        value: true,
                        type: "global",
                        isDefault: false,
                        element: undefined,
                        path: undefined
                    }
                ]
            },
            {
                id: 31,
                type: "item",
                path: "reports/equipment-listing",
                title: "NAVIGATION.CONDUCT.EQUIPMENT_LISTING_REPORTS",
                folder: "reports",
                element: "EquipmentListingReports",
                parentId: 27,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 31,
                    hidden: false,
                    icon: "notepad-edit",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Equipment Listing Reports",
                        key: "equipment-listing-reports",
                        value: true,
                        type: "global",
                        isDefault: false,
                        element: undefined,
                        path: undefined
                    }
                ]
            },

            // Configuration
            {
                id: 32,
                type: "list",
                path: "configuration",
                title: "NAVIGATION.CONDUCT.CONFIGURATION",
                folder: undefined,
                element: undefined,
                parentId: 1,
                generateCRUD: true,
                level: 2,
                menu_order: undefined,
                actions: [],
                sidebarOptions: {
                    module_id: 32,
                    hidden: false,
                    icon: "setting",
                    iconType: "outline"
                }
            },
            {
                id: 33,
                type: "item",
                path: "configuration/event-naming-convention",
                title: "NAVIGATION.CONDUCT.EVENT_NAMING_CONVENTION",
                folder: "configuration",
                element: "EventNamingConvention",
                parentId: 32,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 33,
                    hidden: false,
                    icon: "click",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Event Naming Convention",
                        key: "event-naming-convention",
                        value: true,
                        type: "global",
                        isDefault: false,
                        element: undefined,
                        path: undefined
                    }
                ]
            },
            {
                id: 34,
                type: "item",
                path: "configuration/profile",
                title: "NAVIGATION.CONDUCT.PROFILE",
                folder: "myProfile",
                element: "MyProfile",
                parentId: 32,
                generateCRUD: false,
                level: 4,
                menu_order: undefined,
                sidebarOptions: {
                    module_id: 34,
                    hidden: false,
                    icon: "profile-circle",
                    iconType: "outline"
                },
                actions: [
                    {
                        title: "Profile",
                        key: "profile",
                        value: true,
                        type: "global",
                        isDefault: false,
                        element: undefined,
                        path: undefined
                    }
                ]
            },

            {
                id: -8,
                type: 'item',
                path: 'profile',
                element: "MyProfile",
                title: "NAVIGATION.CONDUCT.PROFILE",
                parentId: -1,
                sidebarOptions: {
                    icon: "switch",
                    hidden: true
                },
            },
        ]
    },
    message: "",
    status: true
}