import { Cookies, } from "react-cookie";
import axios from "axios";
import { getToken } from "./session";
import { TOKEN } from "../modules/auth/AuthHelper";
import axiosInstance from "../network/httpClient";
import { IExportOptions } from "../components/ExportButton/ExportButton";
import CryptoJS from 'crypto-js';
import dayjs from "dayjs";
// import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// @ts-ignore
import html2pdf from 'html2pdf.js'
import Swal, { SweetAlertOptions } from "sweetalert2";
const CRON_JOB_TIME = { hour: 12, min: 0 }
// dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

export const formatArrayField = (
    data: { [x: string]: any; },
    fieldName: any,
    idField = undefined,
    renderValue: (arg0: any, arg1: any) => any
) => {
    const s: any = {};
    if (data) {
        let newData = data;
        if (typeof data === "object") newData = Object.keys(data);
        newData.forEach((k: string | number, i: any) => {
            s[`${fieldName}[${i}]`] = idField && data[k]?.[idField]
                ? data[k]?.[idField]
                : renderValue
                    ? renderValue(data[k], i)
                    : data[k];
        });
    } else s[`${fieldName}[]`] = null;
    return s;
};

export const formatDropdownData = (
    array: any[],
    key = "",
    value = "",
    dataAttrKey = null
) => {
    if (!key) {
        const formattedData = array?.map((item, index) => ({
            key: `${item}-${index}`,
            label: item?.toLowerCase().replace(/\_/g, " "),
            value: item,
        }));
        return formattedData;
    }

    if (array) {
        const itemValue = value ? value : key;
        const formattedData = array?.map((item, index) =>
            dataAttrKey
                ? {
                    key: item[itemValue],
                    label: item[key],
                    value: item[itemValue],
                    [`data-${dataAttrKey}`]: item[dataAttrKey],
                }
                : {
                    key: item[itemValue],
                    label: item[key],
                    value: item[itemValue],
                }
        );
        return formattedData;
    }

    return [];
};

export const getFlags = ($code: string) => {
    $code = $code.toUpperCase();
    if ($code === "AD") return "🇦🇩";
    if ($code === "AE") return "🇦🇪";
    if ($code === "AF") return "🇦🇫";
    if ($code === "AG") return "🇦🇬";
    if ($code === "AI") return "🇦🇮";
    if ($code === "AL") return "🇦🇱";
    if ($code === "AM") return "🇦🇲";
    if ($code === "AO") return "🇦🇴";
    if ($code === "AQ") return "🇦🇶";
    if ($code === "AR") return "🇦🇷";
    if ($code === "AS") return "🇦🇸";
    if ($code === "AT") return "🇦🇹";
    if ($code === "AU") return "🇦🇺";
    if ($code === "AW") return "🇦🇼";
    if ($code === "AX") return "🇦🇽";
    if ($code === "AZ") return "🇦🇿";
    if ($code === "BA") return "🇧🇦";
    if ($code === "BB") return "🇧🇧";
    if ($code === "BD") return "🇧🇩";
    if ($code === "BE") return "🇧🇪";
    if ($code === "BF") return "🇧🇫";
    if ($code === "BG") return "🇧🇬";
    if ($code === "BH") return "🇧🇭";
    if ($code === "BI") return "🇧🇮";
    if ($code === "BJ") return "🇧🇯";
    if ($code === "BL") return "🇧🇱";
    if ($code === "BM") return "🇧🇲";
    if ($code === "BN") return "🇧🇳";
    if ($code === "BO") return "🇧🇴";
    if ($code === "BQ") return "🇧🇶";
    if ($code === "BR") return "🇧🇷";
    if ($code === "BS") return "🇧🇸";
    if ($code === "BT") return "🇧🇹";
    if ($code === "BV") return "🇧🇻";
    if ($code === "BW") return "🇧🇼";
    if ($code === "BY") return "🇧🇾";
    if ($code === "BZ") return "🇧🇿";
    if ($code === "CA") return "🇨🇦";
    if ($code === "CC") return "🇨🇨";
    if ($code === "CD") return "🇨🇩";
    if ($code === "CF") return "🇨🇫";
    if ($code === "CG") return "🇨🇬";
    if ($code === "CH") return "🇨🇭";
    if ($code === "CI") return "🇨🇮";
    if ($code === "CK") return "🇨🇰";
    if ($code === "CL") return "🇨🇱";
    if ($code === "CM") return "🇨🇲";
    if ($code === "CN") return "🇨🇳";
    if ($code === "CO") return "🇨🇴";
    if ($code === "CR") return "🇨🇷";
    if ($code === "CU") return "🇨🇺";
    if ($code === "CV") return "🇨🇻";
    if ($code === "CW") return "🇨🇼";
    if ($code === "CX") return "🇨🇽";
    if ($code === "CY") return "🇨🇾";
    if ($code === "CZ") return "🇨🇿";
    if ($code === "DE") return "🇩🇪";
    if ($code === "DJ") return "🇩🇯";
    if ($code === "DK") return "🇩🇰";
    if ($code === "DM") return "🇩🇲";
    if ($code === "DO") return "🇩🇴";
    if ($code === "DZ") return "🇩🇿";
    if ($code === "EC") return "🇪🇨";
    if ($code === "EE") return "🇪🇪";
    if ($code === "EG") return "🇪🇬";
    if ($code === "EH") return "🇪🇭";
    if ($code === "ER") return "🇪🇷";
    if ($code === "ES") return "🇪🇸";
    if ($code === "ET") return "🇪🇹";
    if ($code === "FI") return "🇫🇮";
    if ($code === "FJ") return "🇫🇯";
    if ($code === "FK") return "🇫🇰";
    if ($code === "FM") return "🇫🇲";
    if ($code === "FO") return "🇫🇴";
    if ($code === "FR") return "🇫🇷";
    if ($code === "GA") return "🇬🇦";
    if ($code === "GB") return "🇬🇧";
    if ($code === "GD") return "🇬🇩";
    if ($code === "GE") return "🇬🇪";
    if ($code === "GF") return "🇬🇫";
    if ($code === "GG") return "🇬🇬";
    if ($code === "GH") return "🇬🇭";
    if ($code === "GI") return "🇬🇮";
    if ($code === "GL") return "🇬🇱";
    if ($code === "GM") return "🇬🇲";
    if ($code === "GN") return "🇬🇳";
    if ($code === "GP") return "🇬🇵";
    if ($code === "GQ") return "🇬🇶";
    if ($code === "GR") return "🇬🇷";
    if ($code === "GS") return "🇬🇸";
    if ($code === "GT") return "🇬🇹";
    if ($code === "GU") return "🇬🇺";
    if ($code === "GW") return "🇬🇼";
    if ($code === "GY") return "🇬🇾";
    if ($code === "HK") return "🇭🇰";
    if ($code === "HM") return "🇭🇲";
    if ($code === "HN") return "🇭🇳";
    if ($code === "HR") return "🇭🇷";
    if ($code === "HT") return "🇭🇹";
    if ($code === "HU") return "🇭🇺";
    if ($code === "ID") return "🇮🇩";
    if ($code === "IE") return "🇮🇪";
    if ($code === "IL") return "🇮🇱";
    if ($code === "IM") return "🇮🇲";
    if ($code === "IN") return "🇮🇳";
    if ($code === "IO") return "🇮🇴";
    if ($code === "IQ") return "🇮🇶";
    if ($code === "IR") return "🇮🇷";
    if ($code === "IS") return "🇮🇸";
    if ($code === "IT") return "🇮🇹";
    if ($code === "JE") return "🇯🇪";
    if ($code === "JM") return "🇯🇲";
    if ($code === "JO") return "🇯🇴";
    if ($code === "JP") return "🇯🇵";
    if ($code === "KE") return "🇰🇪";
    if ($code === "KG") return "🇰🇬";
    if ($code === "KH") return "🇰🇭";
    if ($code === "KI") return "🇰🇮";
    if ($code === "KM") return "🇰🇲";
    if ($code === "KN") return "🇰🇳";
    if ($code === "KP") return "🇰🇵";
    if ($code === "KR") return "🇰🇷";
    if ($code === "KW") return "🇰🇼";
    if ($code === "KY") return "🇰🇾";
    if ($code === "KZ") return "🇰🇿";
    if ($code === "LA") return "🇱🇦";
    if ($code === "LB") return "🇱🇧";
    if ($code === "LC") return "🇱🇨";
    if ($code === "LI") return "🇱🇮";
    if ($code === "LK") return "🇱🇰";
    if ($code === "LR") return "🇱🇷";
    if ($code === "LS") return "🇱🇸";
    if ($code === "LT") return "🇱🇹";
    if ($code === "LU") return "🇱🇺";
    if ($code === "LV") return "🇱🇻";
    if ($code === "LY") return "🇱🇾";
    if ($code === "MA") return "🇲🇦";
    if ($code === "MC") return "🇲🇨";
    if ($code === "MD") return "🇲🇩";
    if ($code === "ME") return "🇲🇪";
    if ($code === "MF") return "🇲🇫";
    if ($code === "MG") return "🇲🇬";
    if ($code === "MH") return "🇲🇭";
    if ($code === "MK") return "🇲🇰";
    if ($code === "ML") return "🇲🇱";
    if ($code === "MM") return "🇲🇲";
    if ($code === "MN") return "🇲🇳";
    if ($code === "MO") return "🇲🇴";
    if ($code === "MP") return "🇲🇵";
    if ($code === "MQ") return "🇲🇶";
    if ($code === "MR") return "🇲🇷";
    if ($code === "MS") return "🇲🇸";
    if ($code === "MT") return "🇲🇹";
    if ($code === "MU") return "🇲🇺";
    if ($code === "MV") return "🇲🇻";
    if ($code === "MW") return "🇲🇼";
    if ($code === "MX") return "🇲🇽";
    if ($code === "MY") return "🇲🇾";
    if ($code === "MZ") return "🇲🇿";
    if ($code === "NA") return "🇳🇦";
    if ($code === "NC") return "🇳🇨";
    if ($code === "NE") return "🇳🇪";
    if ($code === "NF") return "🇳🇫";
    if ($code === "NG") return "🇳🇬";
    if ($code === "NI") return "🇳🇮";
    if ($code === "NL") return "🇳🇱";
    if ($code === "NO") return "🇳🇴";
    if ($code === "NP") return "🇳🇵";
    if ($code === "NR") return "🇳🇷";
    if ($code === "NU") return "🇳🇺";
    if ($code === "NZ") return "🇳🇿";
    if ($code === "OM") return "🇴🇲";
    if ($code === "PA") return "🇵🇦";
    if ($code === "PE") return "🇵🇪";
    if ($code === "PF") return "🇵🇫";
    if ($code === "PG") return "🇵🇬";
    if ($code === "PH") return "🇵🇭";
    if ($code === "PK") return "🇵🇰";
    if ($code === "PL") return "🇵🇱";
    if ($code === "PM") return "🇵🇲";
    if ($code === "PN") return "🇵🇳";
    if ($code === "PR") return "🇵🇷";
    if ($code === "PS") return "🇵🇸";
    if ($code === "PT") return "🇵🇹";
    if ($code === "PW") return "🇵🇼";
    if ($code === "PY") return "🇵🇾";
    if ($code === "QA") return "🇶🇦";
    if ($code === "RE") return "🇷🇪";
    if ($code === "RO") return "🇷🇴";
    if ($code === "RS") return "🇷🇸";
    if ($code === "RU") return "🇷🇺";
    if ($code === "RW") return "🇷🇼";
    if ($code === "SA") return "🇸🇦";
    if ($code === "SB") return "🇸🇧";
    if ($code === "SC") return "🇸🇨";
    if ($code === "SD") return "🇸🇩";
    if ($code === "SE") return "🇸🇪";
    if ($code === "SG") return "🇸🇬";
    if ($code === "SH") return "🇸🇭";
    if ($code === "SI") return "🇸🇮";
    if ($code === "SJ") return "🇸🇯";
    if ($code === "SK") return "🇸🇰";
    if ($code === "SL") return "🇸🇱";
    if ($code === "SM") return "🇸🇲";
    if ($code === "SN") return "🇸🇳";
    if ($code === "SO") return "🇸🇴";
    if ($code === "SR") return "🇸🇷";
    if ($code === "SS") return "🇸🇸";
    if ($code === "ST") return "🇸🇹";
    if ($code === "SV") return "🇸🇻";
    if ($code === "SX") return "🇸🇽";
    if ($code === "SY") return "🇸🇾";
    if ($code === "SZ") return "🇸🇿";
    if ($code === "TC") return "🇹🇨";
    if ($code === "TD") return "🇹🇩";
    if ($code === "TF") return "🇹🇫";
    if ($code === "TG") return "🇹🇬";
    if ($code === "TH") return "🇹🇭";
    if ($code === "TJ") return "🇹🇯";
    if ($code === "TK") return "🇹🇰";
    if ($code === "TL") return "🇹🇱";
    if ($code === "TM") return "🇹🇲";
    if ($code === "TN") return "🇹🇳";
    if ($code === "TO") return "🇹🇴";
    if ($code === "TR") return "🇹🇷";
    if ($code === "TT") return "🇹🇹";
    if ($code === "TV") return "🇹🇻";
    if ($code === "TW") return "🇹🇼";
    if ($code === "TZ") return "🇹🇿";
    if ($code === "UA") return "🇺🇦";
    if ($code === "UG") return "🇺🇬";
    if ($code === "UM") return "🇺🇲";
    if ($code === "US") return "🇺🇸";
    if ($code === "UY") return "🇺🇾";
    if ($code === "UZ") return "🇺🇿";
    if ($code === "VA") return "🇻🇦";
    if ($code === "VC") return "🇻🇨";
    if ($code === "VE") return "🇻🇪";
    if ($code === "VG") return "🇻🇬";
    if ($code === "VI") return "🇻🇮";
    if ($code === "VN") return "🇻🇳";
    if ($code === "VU") return "🇻🇺";
    if ($code === "WF") return "🇼🇫";
    if ($code === "WS") return "🇼🇸";
    if ($code === "XK") return "🇽🇰";
    if ($code === "YE") return "🇾🇪";
    if ($code === "YT") return "🇾🇹";
    if ($code === "ZA") return "🇿🇦";
    if ($code === "ZM") return "🇿🇲";
    return "🏳";
};

// export const formatDropdown = (options, key, value, isEdit = false) => {
//   if (!options) {
//     return [];
//   }
//   return options
//     ?.map((v) => {
//       if (isEdit) {
//         if (v?.id !===parseInt(params?.id))
//           return { value: v?.id, label: v?.categoryName };
//       }
//       return { value: v?.id, label: v?.categoryName };
//     })
//     .filter((v) => v);
// };

export const tryParseJSONObject = (jsonString: string) => {
    try {
        const o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    } catch (e) { }

    return false;
};

export const getFilePath = (path: any[], filePathKey = "filePath") =>
    path?.length
        ? path?.map((photo) => photo?.[filePathKey])?.filter((v1) => v1)
        : null;

export const setCookie = ({ name, value, options = { path: "/" } }: { name: string, value: any, options?: any }) => {
    new Cookies().set(name, value, options);
};

export const getCookie = (name: string) => new Cookies().get(name);

export const toBase64 = (file: Blob) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });


export const toBase64Array = async (files: any[], onlyBase64 = false) => {
    const allPromises = files.map(
        (file) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                if (onlyBase64) reader.onload = () => resolve(reader.result);
                else
                    reader.onload = () =>
                        resolve({ name: file.name, size: file.size, type: file.type, base64: reader.result });

                reader.onerror = error => reject(error);
            }),
    );
    return Promise.all(allPromises).then(result => result);
};


export const flattenJsonObject = (jsonObject: any) => {
    return jsonObject.flatMap((item: any) => {
        const flattenedItem = {
            ...item,
        };

        const flattenedChildren = item.children
            ? flattenJsonObject(item.children)
            : [];

        return [flattenedItem, ...flattenedChildren];
    });
};


export const downloadURI = (uri: string, name: string) => {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {

        document.body.removeChild(link);
    }, 250);
}


export const downloadFile = async (uri: string, name: string) => {

    try {
        const response = await axios({
            url: uri,
            method: 'GET',
            responseType: 'blob', // Important
            headers: {
                Authorization: `Bearer ${getToken(TOKEN)}`
            }
        });

        // Create a new Blob object using the response data of the file
        const blob = new Blob([response.data], { type: response.data.type });

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', name);  // Replace with your desired file name

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        if (link.parentNode)
            link.parentNode.removeChild(link);

    } catch (error) {
        console.error('Error downloading the file', error);
    }
};

export const toCamelCase = (input: string): string => {
    return input
        .split(' ')
        .map((word, index) =>
            index === 0
                ? word.toLowerCase()
                : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join('');
}

export const toSnakeCase = (input: string): string => {
    return input.replace(/ /g, '-').toLowerCase();
}

export const capitalizeWords = (sentence: string): string => {
    const words: string[] = sentence.split(" ");
    const camelCaseString: string = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join("");
    return camelCaseString;
}

export const capitalizeWordsWithSpace = (sentence: string): string => {
    const words: string[] = sentence.split(" ");
    const camelCaseString: string = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
    return camelCaseString.trim();
}

export const jsonToUrlEncoded = (json: any) => {
    let urlEncodedString = '';
    for (const key in json) {
        if (json.hasOwnProperty(key)) {
            if (urlEncodedString.length > 0) {
                urlEncodedString += '&';
            }
            urlEncodedString += encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
        }
    }
    return urlEncodedString;
}

export const extractBase64 = (base64: string) => {
    return base64.split(',')[1]
}
export const convertBytesToKB = (bytes: number, kbPerByte: number) => Math.round(bytes / kbPerByte);


export const searchFilter = (data: any[], searchTerm: string, dataSearchKey?: string) => {
    if (searchTerm)
        return data.filter(item => dataSearchKey ? (item?.[dataSearchKey])?.toLowerCase().includes(searchTerm.toLowerCase()) :
            JSON.stringify(item)?.toLowerCase().includes(searchTerm.toLowerCase())
        );
    return data
}

export const objectToFormData = (obj: any, rootName: string, ignoreList?: any) => {
    const formData = new FormData();

    function appendFormData(data: any, root: string) {
        if (!ignore(root)) {
            root = root || '';
            if (data instanceof File) {
                formData.append(root, data);
            } else if (Array.isArray(data)) {
                for (let i = 0; i < data.length; i++) {
                    appendFormData(data[i], root + '[' + i + ']');
                }
            } else if (typeof data === 'object' && data) {
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            appendFormData(data[key], key);
                        } else {
                            appendFormData(data[key], root + '.' + key);
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data);
                }
            }
        }
    }

    function ignore(root: string) {
        return Array.isArray(ignoreList)
            && ignoreList.some(function (x) { return x === root; });
    }

    appendFormData(obj, rootName);

    return formData;
}


export const generateTimeSlotOptions = () => {
    const timeSlots: {
        label: string;
        value: string;
    }[] = [];
    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0); // Set start time to 00:00:00
    const endTime = new Date();
    endTime.setHours(23, 59, 59, 999); // Set end time to 23:59:59

    while (startTime < endTime) {
        const timeLabel = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        timeSlots.push({ label: timeLabel, value: timeLabel });
        startTime.setTime(startTime.getTime() + 30 * 60 * 1000); // Increment by 30 minutes
    }

    return timeSlots;
}

export function convertTimeToDecimal(timeString: string): string | number {
    if (!timeString)
        return timeString;
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours + minutes / 60;
}
export function convertDecimalToTime(decimalTime: number): string {
    const hours = Math.floor(decimalTime);
    const minutes = Math.round((decimalTime - hours) * 60);
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
}

export function ordinal(n: number) {
    const suffix = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (suffix[(v - 20) % 10] || suffix[v] || suffix[0]);
}

export const exportFile = async ({ url, fileName, payload, withDate = false, date }: IExportOptions) => {
    const { data } = await axiosInstance.post(url, {
        operation: 'export',
        ...payload
    });
    if (data.status) {
        const [file_name, ext] = fileName?.split('.');
        const formattedfileName = withDate ? date ? `${file_name}_${date}.${ext}` : `${file_name}_${dayjs().format('DD-MM-YYYY')}.${ext}` : fileName
        downloadFile(data?.file_name, formattedfileName)
    }
    return data
}

export function formatINR(amount: number, noSymbol?: boolean): string {
    // Check if the amount is negative
    const isNegative = amount < 0;
    // Convert the amount to a positive value for formatting
    const absoluteAmount = Math.abs(amount);

    // Convert the amount to a string with two decimal points
    const amountStr = absoluteAmount.toFixed(2);
    // Split the amount into whole and decimal parts
    const [whole, decimal] = amountStr.split('.');

    // Insert commas according to Indian numbering system
    const n = whole.length;
    let formattedAmount;
    if (n <= 3) {
        formattedAmount = whole + '.' + decimal;
    } else if (n <= 5) {
        formattedAmount = whole.slice(0, n - 3) + ',' + whole.slice(n - 3) + '.' + decimal;
    } else {
        const lastThree = whole.slice(n - 3);
        const otherNumbers = whole.slice(0, n - 3);
        const formattedWhole = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + ',' + lastThree;
        formattedAmount = formattedWhole + '.' + decimal;
    }

    // Add the currency symbol and negative sign if applicable
    if (noSymbol)
        return formattedAmount;
    return (isNegative ? '-₹' : '₹') + formattedAmount;
}


export const generateHash = (inputString: string) => {
    const hashValue = CryptoJS.SHA512(inputString).toString(CryptoJS.enc.Hex);
    return hashValue;
};

export const generatePaymentLink = async ({ customer_id = 0, name = '', phone = '', amount = "", email = '' }) => {


    try {
        const KEY = import.meta.env.VITE_APP_PAYMENT_EASEBUZZ_PROD_KEY;
        const SALT = import.meta.env.VITE_APP_PAYMENT_EASEBUZZ_PROD_SALT;
        const merchant_txn = `VCHN${customer_id}_${new Date().getTime()}`;
        const MSG = 'Wallet Recharge'

        const hash = generateHash(`${KEY}|${merchant_txn}|${name}|${email}|${phone}|${amount}|${customer_id}|||||${MSG}|${SALT}`)

        const options = {
            method: 'POST',
            url: 'https://dashboard.easebuzz.in/easycollect/v1/create',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            data: {
                merchant_txn: `${merchant_txn}`,
                key: KEY,
                email,
                name,
                amount,
                phone: `${phone}`,
                udf1: `${customer_id}`,
                udf2: "",
                udf3: "",
                udf4: "",
                udf5: "",
                message: MSG,
                hash,
                operation: [
                    { type: 'sms', template: 'Default sms template' },
                    { type: "email", template: "Default email template" },
                ],
            }
        };

        const { data } = await axios.request(options);

        if (data?.status !== 'false') {
            return { status: false, data: null, message: "Error while generating link" }
        } else
            return { status: true, data: data?.data, message: data?.message || 'Link generated successfully' };
    } catch (error) {
        console.error(error);
        return { status: false, data: null, message: "Error while generating link" }
    }
}

export const sendPaymentLink = async (result: { data: any; message: string; status: boolean, msg_type: string }) => {

    try {
        if (result.status) {
            const KEY = import.meta.env.VITE_APP_PAYMENT_EASEBUZZ_PROD_KEY;
            const SALT = import.meta.env.VITE_APP_PAYMENT_EASEBUZZ_PROD_SALT;
            const MSG_TEMPLATE = result?.msg_type === 'sms' ? 'Default sms template' : 'Default email template';
            const MSG_TYPE = result?.msg_type === 'sms' ? 'sms' : 'email';
            const PAYMENT_URL = result?.data?.payment_url;
            const hash = generateHash(`${KEY}|${MSG_TEMPLATE}|${PAYMENT_URL}|${MSG_TYPE}|${SALT}`)
            const url = result?.msg_type === 'sms' ? 'https://dashboard.easebuzz.in/easycollect/v1/send_sms' : 'https://dashboard.easebuzz.in/easycollect/v1/send_email'
            const options = {
                method: 'POST',
                url,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                data: {
                    sms_template_name: MSG_TEMPLATE,
                    key: KEY,
                    payment_url: PAYMENT_URL,
                    msg_type: MSG_TYPE,
                    hash
                }
            };

            const { data } = await axios.request(options);
            return { status: true, data };
        } else {
            return { status: false, message: "Error while sending message" }
        }

    } catch (error) {
        console.error(error);
        return { status: false, message: "Error while sending message" }
    }
}

export const getDateRanges = ({ rangeType, date }: { rangeType: any, date?: string, }) => {
    if (!rangeType) {
        return { startDate: null, endDate: null }
    }
    if (rangeType === 'today')
        return {
            startDate: dayjs().format('DD-MM-YYYY'),
            endDate: dayjs().format('DD-MM-YYYY'),
        }
    const inputDate = date ? dayjs(date) : dayjs();
    const startDate = inputDate.startOf(rangeType).format('DD-MM-YYYY');
    const endDate = inputDate.endOf(rangeType).format('DD-MM-YYYY');

    return {
        startDate,
        endDate
    };
}

export const calculateCronTime = (options?: { dayAfterCronTime?: number, dayBeforeCronTime?: number }) => {
    const cronTime = dayjs().hour(CRON_JOB_TIME.hour).minute(CRON_JOB_TIME.min).second(0);
    const minDate = dayjs().isAfter(cronTime) ? dayjs().add(options?.dayAfterCronTime || 1, 'day') : dayjs().add(options?.dayBeforeCronTime || 0, 'day');
    return { cronTime, minDate }
}

export const correctDateFormat = (date: any) => typeof date !== 'string' ? dayjs(date).format('DD-MM-YYYY') : date

export const filterSearch = ({ data, search, key }: { data: any[], search: string, key: string }): any[] => {
    const s = data.filter(item => {
        return (item?.[key].toLowerCase()).includes(search?.toLowerCase())
    }
    );
    if (s?.length)
        return s
    if (search === '')
        return data
    return []
}

export const printPdf = async ({ elementId, fileName, options }: { elementId: string; fileName: string; options?: any }) => {
    try {
        const element = document.getElementById(elementId);
        const opt = {


            filename: `${fileName}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            jsPDF: { unit: 'mm', format: 'a3', orientation: 'p' },
            pagebreak: { mode: 'avoid-all' },
            ...options

        };
        await html2pdf()
            .from(element)
            .set(opt)
            .toContainer()
            .toPdf()
            .save()
        return true
    } catch (error) {
        return false
    }

}

export const addPageBreaks = (itemSelecter: any) => {
    const A4_PAGE_HEIGHT = 1122; // Height in pixels for A4 page at 96 DPI

    // const rows = tableRef.current.querySelectorAll('tr');
    let cumulativeHeight = 250;

    itemSelecter.forEach((row: any, index: number) => {
        const rowHeight = row.offsetHeight;
        cumulativeHeight += rowHeight;

        if (cumulativeHeight > A4_PAGE_HEIGHT) {
            const pageBreakDiv = document.createElement('div');
            pageBreakDiv.className = 'html2pdf__page-break';

            // Insert page break div before the current row
            row.parentNode.insertBefore(pageBreakDiv, row);
            cumulativeHeight = rowHeight; // Reset height after page break
        }
    });
};

export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export function generateLast50Years(): number[] {
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = 0; i < 50; i++) {
        years.push(currentYear - i);
    }

    return years;
}

export const downloadFromBlob = ({ blob, fileName }: { blob: any; fileName: string }) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode)
        link.parentNode.removeChild(link);
}
export const isBeforeDay = (orders: any, day: number, date_key?: string): boolean => {
    const today = dayjs();
    const isBeforeDate = today.subtract(day, 'day');
    isBeforeDate.set('hour', 0);
    isBeforeDate.set('minute', 0);
    isBeforeDate.set('second', 0);
    isBeforeDate.set('millisecond', 0);
    if (!Array.isArray(orders)) {
        const orderDate = dayjs(orders, 'DD-MM-YYYY');
        return isBeforeDate.unix() <= orderDate.unix()

    }

    return orders.some((order: any) => {
        const orderDate = date_key ? dayjs(order?.[date_key], 'DD-MM-YYYY') : dayjs();
        return isBeforeDate.unix() <= orderDate.unix();
    });
};

export const reloadPage = () => window.location.reload();

export const sweetAlert = ({ message, options = {
   
} }: { message: string; options?: SweetAlertOptions }) => Swal.mixin({

    ...options
}).fire(message)