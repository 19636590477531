import { IModuleAction, IModules } from "../models/Modules.interfaces";
import { DEFAULT_ACTION, defaultActionArray } from "./constants";

export const formatNavigation = (data: IModules[]): IModules[] => {
   const indexedData: { [id: number]: IModules } = {};
   data.forEach(item => {
      indexedData[item.id] = (item.type === 'item') ? item : { ...item, children: [] };
   });
   const hierarchy: IModules[] = [];
   data.forEach(item => {
      if (item.parentId && indexedData[item.parentId]) {

         indexedData[item.parentId]?.children?.push(indexedData[item.id]);
      } else {
         hierarchy.push(indexedData[item.id]);
      }
   });

   return hierarchy;
};


export const createDefaultActionValue = (x: { [x: string]: boolean }, otherActions?: IModuleAction[]) => {

   const actions = defaultActionArray.map(v => ({ ...v, value: x?.[v.key] === true }))
   if (otherActions)
      return [...actions, ...otherActions]

   return actions;
}


export function extendListViewActions<T extends string | number | symbol>(actions: IModuleAction[], actionsOptions: { [x: string]: Omit<IModuleAction, T> }) {
   const actionss = actions.map(v => actionsOptions?.[v.key] ? ({ ...v, ...actionsOptions?.[v.key] }) : v)
   return actionss
}



