
import { ISidebarCounter } from './app.interfaces';
import { IRolesAndPersmissions } from './modules/auth/models/Auth.interface';
import { IResponse } from './network/network.interfaces';
import { api } from './services/api/api';
import { DashBoardRelatedApi, TelementryApi, USER_MANAGEMENT_BASE_URL, VendorProfileApi } from './services/api/api.constant';



export const authApi = api
    .injectEndpoints({
        endpoints: builder => ({
            getRolesAndPermissions: builder.query<IResponse<IRolesAndPersmissions>, { userId: string }>({
                query: () => ({
                    url: `${USER_MANAGEMENT_BASE_URL}/modules/getModules`,
                    method: 'GET',
                }),
                providesTags: () => [{ type: "Modules", id: 'PERMISSIONS' }]
            }),
            checkApproval: builder.mutation<IResponse<any>, void>({
                query: () => ({
                    url: `${VendorProfileApi}/checkApproval`,
                    method: 'POST',
                    body: {}
                }),
            }),
            getSideBarCounter: builder.query<IResponse<ISidebarCounter[]>, void>({
                query: () => ({
                    url: `${DashBoardRelatedApi}/leftNavigationCounters`,
                    method: 'POST',
                    body: {}
                }),
                providesTags: () => [{ type: "SidebarCounter", id: 'LIST' }]
            }),
            getheartBeat: builder.query<IResponse<{ message: string; status: boolean }>, void>({
                query: () => ({
                    url: `${TelementryApi}/heartBeat`,
                    method: 'POST',
                    body: {}
                }),
            }),
        })
    })

export const {

    useGetRolesAndPermissionsQuery,
    useLazyGetRolesAndPermissionsQuery,
    useGetSideBarCounterQuery,
    useLazyGetSideBarCounterQuery,
    useCheckApprovalMutation,
    useGetheartBeatQuery,
    useLazyGetheartBeatQuery
} = authApi
