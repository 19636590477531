import { forwardRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { appNotification } from '../../app.slice';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

export interface ISelectTypeItem {
    title: string;
    description?: string;
    id: string;
    iconPath?: string;
    iconDarkPath?: string;
    value?: string | number | readonly string[];
}

export interface ISelectType extends React.HTMLAttributes<HTMLInputElement> {
    items: ISelectTypeItem[];
    error?: string;
}

const SelectType = forwardRef<HTMLInputElement, ISelectType>(({ items, error, ...rest }, ref) => {
    const [selectedMode, setSelectedMode] = useState<string | undefined>();
    const dispatch = useDispatch();

    const handleChangeMode = (mode: string) => () => {
        setSelectedMode(mode);
    };

    useEffect(() => {
        if (error) {
            dispatch(
                appNotification({
                    type: 'danger',
                    msg: error,
                    notificationType: 'toast',
                })
            );
        }
    }, [error, dispatch]);

    return (
        <div className="row d-flex align-items-stretch">
            {items.map(({ id, title, description, value, iconDarkPath, iconPath }) => {
                const isSelected = selectedMode === value;

                return (
                    <div key={id} className="col-lg-6">
                        <input
                            type="radio"
                            className="btn-check"
                            id={id}
                            value={value}
                            checked={isSelected}
                            ref={ref}
                            onClick={handleChangeMode(value as string)}
                            {...rest}
                        />
                        <label
                            htmlFor={id}
                            className="d-flex flex-center flex-column cursor-pointer"
                        >
                            <div
                                className={clsx(
                                    'symbol rounded-circle border p-10',
                                    isSelected && 'custom-selected-mode'
                                )}
                                style={
                                    isSelected
                                        ? {
                                            border: '2px solid #eb7704',
                                            padding: 5,
                                            background: '#eb7704',
                                        }
                                        : { background: 'white' }
                                }
                            >
                                <SVG
                                    src={toAbsoluteUrl(iconPath || `media/misc/checked.svg`)}
                                    width={78}
                                    height={78}
                                    title={value as string}
                                    className={clsx(!isSelected && 'd-block', isSelected && 'd-none')}
                                />
                                <SVG
                                    src={toAbsoluteUrl(iconDarkPath || `media/misc/checked-dark.svg`)}
                                    width={78}
                                    height={78}
                                    title={value as string}
                                    className={clsx(isSelected && 'd-block', !isSelected && 'd-none')}
                                />
                            </div>
                            <span className="d-block fw-semibold text-center mt-5">
                                <span className="text-gray-900 fw-bold d-block fs-1 mb-2">
                                    {title}
                                </span>
                                {description && (
                                    <span className="text-muted fw-semibold fs-6">
                                        {description}
                                    </span>
                                )}
                            </span>
                        </label>
                    </div>
                );
            })}
        </div>
    );
});

export default SelectType;
