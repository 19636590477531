export const BASE_URL = `${import.meta.env.VITE_APP_PROD_API_URL}/backend`

export const BACKEND_BASE_URL = `${import.meta.env.VITE_APP_PROD_BACKEND_BASE_URL}`
export const USER_MANAGEMENT_BASE_URL = `${process.env.NODE_ENV === "development"
    ? import.meta.env.VITE_APP_API_URL :
    import.meta.env.VITE_APP_USER_MANAGEMENT_API_URL}`

export const MasterListApi: string = `${BASE_URL}/MasterList`
export const BackendAuthApi: string = `${BASE_URL}/Auth`
export const DashBoardRelatedApi: string = `${BASE_URL}/DashBoardRelated`
export const RegistrationApi: string = `${BASE_URL}/Registration`
export const VendorProfileApi: string = `${BASE_URL}/VendorProfile`
export const TelementryApi: string = `${BASE_URL}/Telementry`
export const TermsAndConditionsApi: string = `${BASE_URL}/MasterTermsAndConditions`
export const UnitBaseApi: string = `${BASE_URL}/MasterUnit`
export const DepartmentBaseApi: string = `${BASE_URL}/MasterDepartment`
export const ProductBaseApi: string = `${BASE_URL}/MasterProduct`
export const MasterListAuthorizedApi: string = `${BASE_URL}/MasterListAuthorized`
export const TenderNewApi: string = `${BASE_URL}/TenderNew`
export const ReverseAuctionNewApi: string = `${BASE_URL}/ReverseAuctionNew`
export const ForwardAuctionNewApi: string = `${BASE_URL}/ForwardAuctionNew`
export const OrganizationApi: string = `${BASE_URL}/MasterOrganization`
export const UtilityApi: string = `${BASE_URL}/Utility`
export const SupplierBaseApi: string = `${BASE_URL}/Supplier`