// src/store/reducers/index.js

import { combineReducers } from '@reduxjs/toolkit';
import { api } from '../app/services/api/api';
import authSlice, { AuthState } from '../app/modules/auth/features/auth.slice';
import appSlice from '../app/app.slice';
import { AppState } from '../app/app.interfaces';

export interface IRootReducer {
  auth: AuthState;
  app: AppState;
}
const rootReducer = combineReducers({
  auth: authSlice,
  app: appSlice,
  [api.reducerPath]: api.reducer
  // Add other reducers here
});

export default rootReducer;
