import { Button } from "react-bootstrap";
import { useIntl } from "react-intl"
import useAuth from "../../modules/auth/hooks/useAuth";
import { useCheckApprovalMutation } from "../../app.api";
import LoadingButton from "../../components/LoadingButton/LoadingButton";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { useDispatch } from "react-redux";
import { refreshLogin } from "../../modules/auth/features/auth.slice";

const PendingApproval = () => {
    const { $t } = useIntl();
    const { logout } = useAuth()
    const dispatch = useDispatch()
    const [checkApproval, { status }] = useCheckApprovalMutation()
    const handleCheckApproval = () => {
        checkApproval().then(({ data }: any) => {
            if (data?.status) {
                dispatch(refreshLogin(data?.data))
            }
        })
    }
    return (

        <div className='d-flex flex-column flex-root'>
            <div className='d-flex flex-column flex-center flex-column-fluid'>
                <div className='d-flex flex-column flex-center text-center p-10'>
                    <div className='card card-flush  w-lg-650px py-5'>
                        <div className='card-body py-15 py-lg-20'>
                            <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>{$t({ id: 'PENDING_APPROVAL.HEADING', 'defaultMessage': 'Pending Approval' })}</h1>
                            <div className='fw-semibold fs-6 text-gray-500 mb-7'>{$t({ id: 'PENDING_APPROVAL.DESCRIPTION', 'defaultMessage': 'Your Profile is pending for approval.' })}</div>
                            <div className="my-5 d-flex gap-2 flex-center">
                                <LoadingButton loading={status === QueryStatus.pending} onClick={handleCheckApproval} className='btn btn-sm btn-primary'>Check Your Status</LoadingButton>
                                <Button onClick={logout} className='btn btn-sm btn-secondary'>Logout</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PendingApproval