import { FC, createContext, useContext, useEffect, useState } from "react"
import { WithChildren } from "../../_metronic/helpers"
import useAuth from "../modules/auth/hooks/useAuth";
import { APP_ROUTES, staticRoutes } from "./routes";
import { IModules } from "../pages/moduleCreator/models/Modules.interfaces";
import { setPermissions } from "../modules/auth/features/auth.slice";
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ROLES } from "../utils/constants";
import { removeSession } from "../utils/session";
import { TOKEN } from "../modules/auth/AuthHelper";
import { updateSidebarCounter } from "../app.slice";
import { ISidebarCounter } from "../app.interfaces";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { useLazyGetRolesAndPermissionsQuery, useLazyGetSideBarCounterQuery } from "../app.api";
import { mockParticipateModeRoutes } from "./mockParticipateModeRoutes";
import { mockConductModeRoutes } from "./mockConductModeRoutes";
import { DEFAULT_ACTION } from "../pages/Modules/utils/constants";
import { createDefaultActionValue } from "../pages/Modules/utils/helpers";


interface IDynamicRouteProps {
  dynamicRoutes: IModules[],
  homeRoute: string,
  isLoading: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  error?: any
}
const DynamicRouteContext = createContext<IDynamicRouteProps>({
  dynamicRoutes: [],
  homeRoute: '',
  isLoading: true,
});

const DynamicRouteProvider: FC<WithChildren> = ({ children }) => {
  const { currentUser, isAuthenticated } = useAuth()
  const [isLoading, setLoading] = useState(true)
  const dispatch: Dispatch = useDispatch()
  const [routes, setRotues] = useState<IModules[]>([])
  const [homeRoute, setHomeRoutes] = useState<string>(APP_ROUTES.DASHBOARD)
  const [getSidebarCounter, { data: sidebarCounter, status }] = useLazyGetSideBarCounterQuery();
  const [getPermissions, { isSuccess, isError, error }] = useLazyGetRolesAndPermissionsQuery()
  useEffect(() => {
    // for production
    // if (currentUser?.user_id) {
    //   getSidebarCounter();
    //   getPermissions({
    //     userId: currentUser?.user_id
    //   })
    //     .then(({ data }) => {

    //       let r: IModules[] = []
    //       let h: string = homeRoute;
    //       // @ts-expect-error TODO: make it array later
    //       const role = currentUser?.role?.[0]?.role || currentUser?.access;

    //       if (role && staticRoutes?.[role]) {
    //         r = [...staticRoutes?.[role]]

    //         if (data?.data?.permissions) {
    //           r = [...r, ...data?.data?.permissions as IModules[]];
    //         }
    //         h = data?.data?.home ? `/${data?.data?.home}` : APP_ROUTES.DASHBOARD

    //       }
    //       else {
    //         // for only dynamic routes are present
    //         r = data?.data?.permissions as IModules[]
    //         h = `/${data?.data?.home}`
    //       }

    //       const formattedRoute = (r);
    //       setRotues(formattedRoute);
    //       dispatch(setPermissions(formattedRoute))
    //       setHomeRoutes(`${h}`);

    //     }).then(() => {

    //     }).catch(e => console.error(e)).finally(() => { setLoading(false) })

    // } else {
    //   const token = isAuthenticated();
    //   if (token) {
    //     removeSession(TOKEN);
    //     window.location.reload()
    //   }
    // }
    // for production end

    // for mocking

    if (currentUser?.access) {
      try {
        const mockPermissions = currentUser?.mode === 'participate' ? mockParticipateModeRoutes : mockConductModeRoutes
        let r: IModules[] = [];
        if (currentUser?.access && staticRoutes?.[currentUser.access])
          r = [...staticRoutes?.[currentUser.access]]
        r = [...r, {
          "id": -3,
          parentId: -1,
          type: 'item',

          "path": APP_ROUTES.MODULES,
          "element": "Modules",
          "title": "Modules",
          "sidebarOptions": {
            "icon": "switch"
          },
          "actions": createDefaultActionValue({
            [DEFAULT_ACTION.CREATE]: true,
            [DEFAULT_ACTION.DELETE]: false,
            [DEFAULT_ACTION.READ]: false,
            [DEFAULT_ACTION.UPDATE]: true
          },
            [
              {
                id: -1,
                key: 'add_action',
                title: 'Add new permission',
                type: 'inline',
                value: true
              },

            ]
          )
        }, ...mockPermissions?.data?.permissions as IModules[]];
        setRotues(r);
        dispatch(setPermissions(r))
        setHomeRoutes(mockPermissions.data?.home);
      } catch (error) {
        console.error('error', error);
      }
    }
    setLoading(false)
    // for mocking end
  }, [currentUser?.user_id, currentUser?.mode])

  useEffect(() => {
    if (status === QueryStatus.fulfilled) {
      let s = {}
      sidebarCounter?.data?.forEach((v: ISidebarCounter) => { s = { ...s, [v.path]: v?.counter } })
      dispatch(updateSidebarCounter(s))
    }

  }, [status, sidebarCounter, dispatch])

  return <DynamicRouteContext.Provider value={{ dynamicRoutes: routes, homeRoute, isLoading, isSuccess, isError, error }}>{children}</DynamicRouteContext.Provider>
}



export { DynamicRouteContext, DynamicRouteProvider }

export function useDynamicRoutes() {
  return useContext(DynamicRouteContext)
}