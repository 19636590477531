import { Suspense, useCallback, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { ThemeModeProvider } from '../_metronic/partials'
import ToastWrapper from './components/Toast/Toast'
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from 'react-redux'
import { QueryStatus } from '@reduxjs/toolkit/query'
import PageLoader from './components/PageLoader/PageLoader'
import { RootState } from '../store/store'
import useSignalR from './services/signalR/useSignalR'
import { useDispatch } from 'react-redux'
import { appNotification, } from './app.slice'
import { signalRNotificationEvent } from './services/signalR/signalREvent'
import { ISignalREventPayload } from './app.interfaces'
import useNetwork from './hooks/useNetwork'
import { isDeAuthenticated } from './modules/auth/features/auth.slice'
import Error500 from './modules/errors/components/Error500'
import { BROADCAST_EVENT } from './components/BrowserEventProvider/BrowserEventProvider'
import useBrowserEventProvider from './components/BrowserEventProvider/useBrowserEventProvider'
import { useLazyGetheartBeatQuery, useLazyGetSideBarCounterQuery } from './app.api'
import useAuth from './modules/auth/hooks/useAuth'

const App = () => {
  const { online } = useNetwork()
  const { connection } = useSignalR()
  const dispatch = useDispatch()
  const { currentUser } = useAuth()
  const [getSidebarCounter] = useLazyGetSideBarCounterQuery();
  const [getHeartBeat] = useLazyGetheartBeatQuery({ pollingInterval: 1000 });
  const { sendTabEvent, broadCastChannelRef } = useBrowserEventProvider()
  const isLoading = useSelector((state: RootState) => {
    const skipApis = ['getheartBeat'];
    return Object.values({ ...state.api.mutations, ...state.api.queries }).some((query) => {
      if (query && skipApis.includes(query?.endpointName as string))
        return false
      return query && query.status === QueryStatus.pending;
    });
  });

  const isDeAuth = useSelector(isDeAuthenticated);
  const onReceiveMessage = useCallback((payload: ISignalREventPayload) => (msg: string) => {
    try {
      const { callback, ...rest } = payload
      if (callback)
        callback(rest)
      dispatch(appNotification({ ...rest, msg }))
    } catch (error) {
      console.error(error);
    }

  }, [dispatch])


  useEffect(() => {
    if (!connection) return
    try {
      Object.keys(signalRNotificationEvent).forEach(key => {
        let s: ISignalREventPayload = signalRNotificationEvent[key];
        if (key === 'logoutUser')
          s = {
            ...s, callback: () => {
              // setEncodedUrl(window.location.pathname)
              // navigate(APP_ROUTES.LOGOUT)
              sendTabEvent(BROADCAST_EVENT.LOGOUT_EVENT, { redirectUrl: window.location.pathname })

            }
          }
        if (key === 'customerTicketMessage' || key === 'ticketCount') {
          s = {
            ...s, callback: () => {
              getSidebarCounter()
            }
          }
        }
        connection.on(key, onReceiveMessage(s));
      })
    } catch (error) {
      console.error(error);
    }

    return () => {
      try {
        Object.keys(signalRNotificationEvent).forEach(key => {
          connection.off(key, onReceiveMessage(signalRNotificationEvent[key]));
        })
      } catch (error) {
        console.error(error);
      }
    }
  }, [connection])

  useEffect(() => {
    console.log('isDeAuth', isDeAuth);

    if (isDeAuth) {
      sendTabEvent(BROADCAST_EVENT.LOGOUT_EVENT, { redirectUrl: window.location.pathname })
    }
  }, [broadCastChannelRef, isDeAuth, sendTabEvent])


  useEffect(() => {
    if (currentUser?.user_id) {
      getHeartBeat()
    }
  }, [currentUser?.user_id])
  return (
    <ErrorBoundary fallback={<div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush  w-lg-650px py-5'>
            <div className='card-body py-15 py-lg-20'>
              <Error500 />
            </div>
          </div>
        </div>
      </div>
    </div>}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          {!online && <div className='d-flex flex-center bg-danger' style={{ zIndex: 10000 }}>
            <span className='fw-bolder text-white'>You are Offline! Please check your Internet connection</span>
          </div>}
          <LayoutProvider>
            <ThemeModeProvider>
              {isLoading && <PageLoader />}
              <Outlet />

              <MasterInit />
              <ToastWrapper />
            </ThemeModeProvider>
          </LayoutProvider>

        </I18nProvider>
      </Suspense>
    </ErrorBoundary>
  )
}

export { App }
