export default {
  "TRANSLATOR.SELECT": "Select your language",
  // site
  "COMMON.TEXT.PARITICIPANT": "Participant",
  "COMMON.TEXT.EMAIL": "Email",
  "COMMON.TEXT.PHONE_NUMBER": "Phone Number",
  "COMMON.TEXT.UNIT": "Unit",
  "COMMON.TEXT.IMPORT": "Import",
  "COMMON.TEXT.UPLOAD": "Upload",
  "COMMON.TEXT.NEXT": "Next",
  "COMMON.TEXT.BACK": "Previous",
  "COMMON.TEXT.FINISH": "Finish",
  "COMMON.TEXT.PUBLISH": "Publish",
  "MENU.HEADER.CONDUCT_MODE": "Conduct",
  "MENU.HEADER.PARTICIPATE_MODE": "Participate",
  "SITE.GENERAL.APP_NAME": "ApnaJugaad.co.in",
  "SITE.GENERAL.DESCRIPTION": "Welcome to ApnaJugaad.co.in",
  "MENU.HEADER.MY_PROFILE": "My Profile",
  "MENU.HEADER.SETTINGS": "Settings",
  "MENU.HEADER.MANAGE_USER": "Manage Users and Roles",
  "MENU.HEADER.HELP": "Help",
  "MENU.HEADER.SIGN_OUT": "Sign Out",
  "MENU.HEADER.ASIDE_PROFILE_COMPLETION": "Profile Completion",
  "MENU.NEW": "new",
  "MENU.ACTIONS": "Actions",
  "MENU.CREATE_POST": "Create New Post",
  "MENU.PAGES": "Pages",
  "MENU.FEATURES": "Features",
  "MENU.APPS": "Apps",
  "MENU.DASHBOARD": "Dashboard",
  "AUTH.GENERAL.OR": "Or",
  "AUTH.GENERAL.SUBMIT_BUTTON": "Submit",
  "AUTH.GENERAL.NO_ACCOUNT": "Don't have an account?",
  "AUTH.GENERAL.SIGNUP_BUTTON": "Sign Up",
  "AUTH.GENERAL.FORGOT_BUTTON": "Forgot Password",
  "AUTH.GENERAL.BACK_BUTTON": "Back",
  "AUTH.GENERAL.PRIVACY": "Privacy",
  "AUTH.GENERAL.LEGAL": "Legal",
  "AUTH.GENERAL.CONTACT": "Contact",
  "AUTH.LOGIN.LOGIN_TYPE": "Sign-in through",
  "AUTH.LOGIN.LOGIN_TYPE_USER_ID": "Your Existing Password",
  "AUTH.LOGIN.LOGIN_TYPE_OTP": "One Time Password (OTP)",
  "AUTH.LOGIN.TITLE": "Sign In",
  "AUTH.LOGIN.OR_WITH_EMAIL": "Or with email",
  "AUTH.LOGIN.ERROR_MESSAGE": "An error occurred: {error}",
  "AUTH.LOGIN.USER_ID.LABEL": "User id",
  "AUTH.LOGIN.USER_ID.PLACEHOLDER": "User id",
  "AUTH.LOGIN.USER_ID.ERROR": "{error}",
  "AUTH.LOGIN.PASSWORD.LABEL": "Password",
  "AUTH.LOGIN.PASSWORD.PLACEHOLDER": "Password",
  "AUTH.LOGIN.PASSWORD.ERROR": "{error}",
  "AUTH.LOGIN.FORGOT_PASSWORD": "Forgot Password?",
  "AUTH.LOGIN.CONTINUE_BUTTON": "Continue",
  "AUTH.LOGIN.LOADING_MESSAGE": "Please wait...",
  "AUTH.LOGIN.CREATE_ACCOUNT_MESSAGE": "Create account?",
  "AUTH.LOGIN.SIGNUP_LINK": "Sign up",
  "AUTH.LOGIN_WITH_PHONE.TITLE": "Sign In",
  "AUTH.LOGIN_WITH_PHONE.SOCIAL_BUTTON_ENABLED": "Or with phone number",
  "AUTH.LOGIN_WITH_PHONE.ERROR_MESSAGE": "Oops something went wrong. Please try again later!",
  "AUTH.LOGIN_WITH_PHONE.PHONE_NUMBER.LABEL": "Phone number",
  "AUTH.LOGIN_WITH_PHONE.PHONE_NUMBER.PLACEHOLDER": "Phone number",
  "AUTH.LOGIN_WITH_PHONE.GET_OTP_BUTTON": "Get OTP",
  "AUTH.LOGIN_WITH_PHONE.LOADING_MESSAGE": "Please wait...",
  "AUTH.LOGIN_WITH_PHONE.CREATE_ACCOUNT": "Create account?",
  "AUTH.LOGIN_WITH_PHONE.SIGNUP_LINK": "Sign up",
  "AUTH.LOGIN_WITH_PHONE.OTP_VERIFY_BUTTON": "Verify OTP",
  "AUTH.LOGIN_WITH_PHONE.VERIFYING_OTP": "Verifying...",
  "AUTH.LOGIN_WITH_PHONE.CANCEL_BUTTON": "Cancel",
  "AUTH.REGISTRATION.BACK_TO_LOGIN": "Back To Login",
  "AUTH.REGISTRATION.STEP_1.HEADING": "General Details",
  "AUTH.REGISTRATION.STEP_2.HEADING": "Business Details",
  "AUTH.REGISTRATION.STEP_3.HEADING": "Tax Details",
  "AUTH.REGISTRATION.STEP_4.HEADING": "Confirm and Submit",
  "AUTH.REGISTRATION.STEP_1.DESCRIPTION": "Setup your basic details",
  "AUTH.REGISTRATION.STEP_2.DESCRIPTION": "Setup your business Details",
  "AUTH.REGISTRATION.STEP_3.DESCRIPTION": "Provide your tax related info",
  "AUTH.REGISTRATION.STEP_4.DESCRIPTION": "Please confirm and submit",
  "AUTH.REGISTRATION.PREVIOUS": "Previous",
  "AUTH.REGISTRATION.NEXT_STEP": "Next Step",
  "AUTH.REGISTRATION.SUBMIT": "Submit",
  "AUTH.REGISTRATION.COMPANY_NAME.LABEL": "Organization Name",
  "AUTH.REGISTRATION.COMPANY_NAME.PLACEHOLDER": "Organization Name",
  "AUTH.REGISTRATION.COMPANY_COUNTRY.LABEL": "Country",
  "AUTH.REGISTRATION.COMPANY_COUNTRY.PLACEHOLDER": "Country",
  "AUTH.REGISTRATION.CONTACT_PERSON.LABEL": "Contact Person Name",
  "AUTH.REGISTRATION.CONTACT_PERSON.PLACEHOLDER": "Contact Person Name",
  "AUTH.REGISTRATION.COMPANY_STATE.LABEL": "States",
  "AUTH.REGISTRATION.COMPANY_STATE.PLACEHOLDER": "States",
  "AUTH.REGISTRATION.COMPANY_CITY.LABEL": "City",
  "AUTH.REGISTRATION.COMPANY_CITY.PLACEHOLDER": "City",
  "AUTH.REGISTRATION.COMPANY_EMAIL_1.LABEL": "Primary Email",
  "AUTH.REGISTRATION.COMPANY_EMAIL_1.PLACEHOLDER": "Primary Email",
  "AUTH.REGISTRATION.COMPANY_EMAIL_2.LABEL": "Secondary Email",
  "AUTH.REGISTRATION.COMPANY_EMAIL_2.PLACEHOLDER": "Secondary Email",
  "AUTH.REGISTRATION.COMPANY_MOBILE_1.LABEL": "Primary phone",
  "AUTH.REGISTRATION.COMPANY_MOBILE_1.PLACEHOLDER": "Primary phone",
  "AUTH.REGISTRATION.COMPANY_MOBILE_2.LABEL": "Secondary phone",
  "AUTH.REGISTRATION.COMPANY_MOBILE_2.PLACEHOLDER": "Secondary phone",
  "AUTH.REGISTRATION.COMPANY_TYPE_OF_BUSINESS.LABEL": "Type of Business",
  "AUTH.REGISTRATION.COMPANY_TYPE_OF_BUSINESS.PLACEHOLDER": "Type of Business",
  "AUTH.REGISTRATION.PRODUCT_GROUP_IDS.LABEL": "Product Group",
  "AUTH.REGISTRATION.PRODUCT_GROUP_IDS.FILTER_PLACEHOLDER": "Search Product group",
  "AUTH.REGISTRATION.OTHER_PRODUCT_GROUP.PLACEHOLDER": "Enter Other Product Group",
  "AUTH.REGISTRATION.VENDOR_TYPE.LABEL": "Type of vendor",
  "AUTH.REGISTRATION.VENDOR_TYPE.PLACEHOLDER": "Type of vendor",
  "AUTH.REGISTRATION.FIRM_TYPE.LABEL": "Firm Type",
  "AUTH.REGISTRATION.FIRM_TYPE.PLACEHOLDER": "Firm Type",
  "AUTH.REGISTRATION.CURRENCY_CODE.LABEL": "Currency",
  "AUTH.REGISTRATION.CURRENCY_CODE.PLACEHOLDER": "Currency",
  "AUTH.REGISTRATION.COMPANY_REGISTRATION_NUMBER.LABEL": "Registration Number",
  "AUTH.REGISTRATION.COMPANY_REGISTRATION_NUMBER.PLACEHOLDER": "Registration Number",
  "AUTH.REGISTRATION.COMPANY_PAN_NUMBER.LABEL": "PAN",
  "AUTH.REGISTRATION.COMPANY_PAN_NUMBER.PLACEHOLDER": "PAN",
  "AUTH.REGISTRATION.COMPANY_TAX_NUMBER.LABEL": "GST",
  "AUTH.REGISTRATION.COMPANY_TAX_NUMBER.PLACEHOLDER": "GST",
  "AUTH.REGISTRATION.UPLOAD_COMPANY_PAN_NUMBER.LABEL": "Upload PAN",
  "AUTH.REGISTRATION.UPLOAD_COMPANY_PAN_NUMBER.PLACEHOLDER": "Upload PAN",
  "AUTH.REGISTRATION.UPLOAD_COMPANY_TAX_NUMBER.LABEL": "Upload GST",
  "AUTH.REGISTRATION.UPLOAD_COMPANY_TAX_NUMBER.PLACEHOLDER": "Upload GST",
  "AUTH.REGISTRATION.POSTAL_ADDRESS.LABEL": "Postal Address",
  "AUTH.REGISTRATION.POSTAL_ADDRESS.PLACEHOLDER": "Postal Address",
  "AUTH.REGISTRATION.PIN_CODE.LABEL": "Pincode",
  "AUTH.REGISTRATION.PIN_CODE.PLACEHOLDER": "Pincode",
  "AUTH.REGISTRATION.PASSWORD.LABEL": "Password",
  "AUTH.REGISTRATION.PASSWORD.PLACEHOLDER": "Password",
  "AUTH.REGISTRATION.CONFIRM_PASSWORD.LABEL": "Confirm Password",
  "AUTH.REGISTRATION.CONFIRM_PASSWORD.PLACEHOLDER": "Confirm Password",
  "AUTH.REGISTRATION.TERMS_AND_CONDITIONS.LABEL": "Terms and Conditions",
  "PASSWORDINPUT.OTP_MODAL.TITLE": "Enter OTP to verify",
  "PASSWORDINPUT.VERIFY.BUTTON": "Verify",
  "PASSWORDINPUT.VERIFY.BUTTON.LOADING": "Verifying",
  "BUTTON.CANCEL": "Cancel",
  "BUTTON.SUBMIT": "Submit",
  "OTPINPUT.LABEL": "Enter OTP",
  "OTPINPUT.RESEND": "Resend",
  "OTPINPUT.RESEND_LOADING": "Resend Otp in {time}...",
  "PENDING_APPROVAL.HEADING": "Pending Approval",
  "PENDING_APPROVAL.DESCRIPTION": "Your Profile is pending for approval.",
  "AUCTION_MODE.PAGE.TITLE": "Choose Mode",
  "AUCTION_MODE.CONDUCT.TITLE": "Conduct Mode",
  "AUCTION_MODE.CONDUCT.DESCRIPTION": "Create Events, Conduct Auction and Manage biddings.",
  "AUCTION_MODE.PARTICIPATE.TITLE": "Participant Mode",
  "AUCTION_MODE.PARTICIPATE.DESCRIPTION": "Participate in Events and bidding",
  "MY_PROFILE.TITLE": "",

  // Tender new 
  "TENDER_NEW_STEP.1.TITLE": "General Details",
  "TENDER_NEW_STEP.2.TITLE": "Document Attachment",
  "TENDER_NEW_STEP.3.TITLE": "Terms and Conditions Attachment",
  "TENDER_NEW_STEP.4.TITLE": "Supplier Selection",
  "TENDER_NEW_STEP.5.TITLE2": "Line Items",
  "TENDER_NEW_STEP.5.TITLE": "Add Line Items",
  "TENDER_NEW_STEP.6.TITLE": "Summary and Publish",
  "TENDER_NEW.FORM.TITLE.LABEL": "Title",
  "TENDER_NEW.FORM.START_DATE.LABEL": "Start Date",
  "TENDER_NEW.FORM.END_DATE.LABEL": "End Date",
  "TENDER_NEW.FORM.COMPANY_NAME.LABEL": "Organization",
  "TENDER_NEW.FORM.CURRENCY_CODE.LABEL": "Currency Code",
  "TENDER_NEW.FORM.HISTORIC_PRICE.LABEL": "Historic Price",
  "TENDER_NEW.FORM.VENDOR_TYPE.LABEL": "Vendor Type",
  "TENDER_NEW.FORM.TENDER_RANGE.LABEL": "Tender Range",
  "TENDER_NEW.FORM.PRODUCT_GROUP.LABEL": "Product Group",
  "TENDER_NEW.FORM.DESCRIPTION.LABEL": "Description",
  "TENDER_NEW.FORM.SHOW_RANKING.LABEL": "Show Ranking",
  "TENDER_NEW.FORM.AUTO_EXTEND.LABEL": "Auto Extend",
  "TENDER_NEW.FORM.SEALED_BID.LABEL": "Sealed Bid",
  "TENDER_NEW.FORM.RFQ_FOR.LABEL": "RFQ For",
  "TENDER_NEW.FORM.RFQ_FOR_BUY.LABEL": "Buy",
  "TENDER_NEW.FORM.RFQ_FOR_SELL.LABEL": "Sell",
  "TENDER_NEW.FORM.SELECT_COMPANY.PLACEHOLDER": "Select Organization",
  "TENDER_NEW.FORM.CURRENCY_CODE.PLACEHOLDER": "Currency Code",
  "TENDER_NEW.FORM.PRODUCT_GROUP.PLACEHOLDER": "Product Group",
  "TENDER_NEW.FORM.TITLE.PLACEHOLDER": "Title",
  "TENDER_NEW.FORM.START_DATE.PLACEHOLDER": "Start Date",
  "TENDER_NEW.FORM.END_DATE.PLACEHOLDER": "End Date",
  "TENDER_NEW.FORM.DESCRIPTION.PLACEHOLDER": "Description",
  "TENDER_NEW.FORM.HISTORIC_PRICE.PLACEHOLDER": "Historic Price",
  "TENDER_NEW.FORM.VENDOR_TYPE.PLACEHOLDER": "Vendor Type",
  "TENDER_NEW.FORM.TENDER_RANGE.PLACEHOLDER": "Tender Range",
  "TENDER_NEW.FORM.FILES.LABEL": "Upload files",
  "TENDER_NEW.FORM.TERMS_AND_CONDITION.LABEL": "Terms and condition",
  "TENDER_NEW.FORM.TERMS_AND_CONDITION.PLACEHOLDER": "Terms and condition",
  "TENDER_NEW.FORM.PR_ID.LABEL": "PR ID",
  "TENDER_NEW.FORM.PR_ID.PLACEHOLDER": "PR ID",
  "TENDER_NEW.FORM.APPROVAL_AUTHORITY.LABEL": "Approval Authority",
  "TENDER_NEW.FORM.APPROVAL_AUTHORITY.PLACEHOLDER": "Approval Authority",
  "TENDER_NEW.FORM.PARITCIPANT.SELECT": "Select Participant",
  "TENDER_NEW.FORM.PRODUCT_GROUP.SELECT": "Select Product Group",
  "TENDER_NEW.FORM.UNIT.SELECT": "Select Unit",
  "TENDER_NEW.FORM.INVITE_PARTICIPANT": "Invite Participant",
  "TENDER_NEW.FORM.ADD_PARTICIPANT": "Add Participant",
  "TENDER_NEW.TITLE": "New RFQ",
  "TENDER_DRAFT.TITLE": "Draft RFQ",
  "TENDER_APPROVE.TITLE": "Approved RFQ",
  // reverse auction event
  "REVERSE_AUCTION_NEW.TITLE": "New Reverse Auction ",
  "REVERSE_AUCTION_NEW_STEP.1.TITLE": "General Details",
  "REVERSE_AUCTION_NEW_STEP.2.TITLE": "Line Items",
  "REVERSE_AUCTION_NEW_STEP.2.TITLE2": "Add Line Items",
  "REVERSE_AUCTION_NEW_STEP.3.TITLE": "Supplier Selection",
  "REVERSE_AUCTION_NEW_STEP.4.TITLE": "Document upload",
  "REVERSE_AUCTION_NEW_STEP.5.TITLE": "Terms and Conditions Attachment",
  "REVERSE_AUCTION_NEW_STEP.6.TITLE": "Summary and Publish",

  "REVERSE_AUCTION_DRAFT.TITLE": "Draft Reverse Auction ",
  "REVERSE_AUCTION_APPROVE.TITLE": "Approved Reverse Auction ",

  // forward auction event
  "FORWARD_AUCTION_NEW.TITLE": "New Forward Auction ",
  "FORWARD_AUCTION_NEW_STEP.1.TITLE": "General Details",
  "FORWARD_AUCTION_NEW_STEP.2.TITLE": "Line Items",
  "FORWARD_AUCTION_NEW_STEP.2.TITLE2": "Add Line Items",
  "FORWARD_AUCTION_NEW_STEP.3.TITLE": "Supplier Selection",
  "FORWARD_AUCTION_NEW_STEP.4.TITLE": "Document upload",
  "FORWARD_AUCTION_NEW_STEP.5.TITLE": "Terms and Conditions Attachment",
  "FORWARD_AUCTION_NEW_STEP.6.TITLE": "Summary and Save",

  "FORWARD_AUCTION_DRAFT.TITLE": "Draft Forward Auction ",
  "FORWARD_AUCTION_APPROVE.TITLE": "Approved Forward Auction ",

  // equipment listing
  "EQUIPMENT_LISTING_NEW.TITLE": "New Equipment Listing ",
  "EQUIPMENT_LISTING_NEW_STEP.1.TITLE": "General Details",
  "EQUIPMENT_LISTING_NEW_STEP.2.TITLE": "Document upload",
  "EQUIPMENT_LISTING_NEW_STEP.3.TITLE": "Terms and Conditions Attachment",
  "EQUIPMENT_LISTING_NEW_STEP.4.TITLE": "Contact Information",
  "EQUIPMENT_LISTING_NEW_STEP.5.TITLE": "Summary and Save",

  "EQUIPMENT_LISTING_DRAFT.TITLE": "Draft Equipment Listing ",
  "EQUIPMENT_LISTING_APPROVE.TITLE": "Approved Equipment Listing ",

  // navigation
  "NAVIGATION.CONDUCT.MAIN": "Main",
  "NAVIGATION.CONDUCT.HOME": "Home",
  "NAVIGATION.CONDUCT.MASTERS": "Masters",
  "NAVIGATION.CONDUCT.USERS": "Users",
  "NAVIGATION.CONDUCT.DEPARTMENT": "Department",
  "NAVIGATION.CONDUCT.PRODUCT": "Product",
  "NAVIGATION.CONDUCT.UNIT": "Unit",
  "NAVIGATION.CONDUCT.TERMS_AND_CONDITION": "Terms And Condition",
  "NAVIGATION.CONDUCT.COMPANY": "Organization",
  "NAVIGATION.CONDUCT.SUPPLIERS": "Suppliers",
  "NAVIGATION.CONDUCT.EVENTS": "Events",
  "NAVIGATION.CONDUCT.TENDER_RFQ": "Tender (RFQ)",
  "NAVIGATION.CONDUCT.NEW": "New",
  "NAVIGATION.CONDUCT.DRAFT": "Draft",
  "NAVIGATION.CONDUCT.APPROVE": "Approve",
  "NAVIGATION.CONDUCT.REVERSE_AUCTION": "Reverse Auction",
  "NAVIGATION.CONDUCT.LIVE_REVERSE_AUCTION": "Live Reverse Auction",
  "NAVIGATION.CONDUCT.FORWARD_AUCTION": "Forward Auction",
  "NAVIGATION.CONDUCT.LIVE_FORWARD_AUCTION": "Live Forward Auction",
  "NAVIGATION.CONDUCT.EQUIPMENT_LISTING": "Equipment Listing",
  "NAVIGATION.CONDUCT.PUBLISH": "Publish",
  "NAVIGATION.CONDUCT.REPORTS": "Reports",
  "NAVIGATION.CONDUCT.TENDER_REPORTS": "Tender",
  "NAVIGATION.CONDUCT.REVERSE_AUCTION_REPORTS": "Reverse Auction",
  "NAVIGATION.CONDUCT.FORWARD_AUCTION_REPORTS": "Forward Auction",
  "NAVIGATION.CONDUCT.EQUIPMENT_LISTING_REPORTS": "Equipment Listing",
  "NAVIGATION.CONDUCT.CONFIGURATION": "Configuration",
  "NAVIGATION.CONDUCT.EVENT_NAMING_CONVENTION": "Event Naming Convention",
  "NAVIGATION.CONDUCT.PROFILE": "Profile",
  "NAVIGATION.PARTICIPATE.MAIN": "Main",
  "NAVIGATION.PARTICIPATE.HOME": "Home",
  "NAVIGATION.PARTICIPATE.PARTICIPATE": "Participate",
  "NAVIGATION.PARTICIPATE.LIVE_TENDER_RFQ": "Live Tender (RFQ)",
  "NAVIGATION.PARTICIPATE.LIVE_REVERSE_AUCTION": "Live Reverse Auction",
  "NAVIGATION.PARTICIPATE.LIVE_FORWARD_AUCTION": "Live Forward Auction",
  "NAVIGATION.PARTICIPATE.REPORTS": "Reports",
  "NAVIGATION.PARTICIPATE.TENDER_REPORTS": "Tender Reports",
  "NAVIGATION.PARTICIPATE.REVERSE_AUCTION_REPORTS": "Reverse Auction Reports",
  "NAVIGATION.PARTICIPATE.FORWARD_AUCTION_REPORTS": "Forward Auction Reports",
  "NAVIGATION.PARTICIPATE.PROFILE": "Profile"

}