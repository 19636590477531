import axios from "axios";
import { getToken } from "../utils/session";
import { TOKEN } from "../modules/auth/AuthHelper";
import { PUBLIC_ROUTES } from "../routing/routes";
import { store } from "../../store/store";
export const API_URL = import.meta.env.VITE_APP_PROD_API_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  config => {
    // Get the token from your storage mechanism (localStorage, etc.)
    const authToken = store.getState().auth.token || getToken(TOKEN);

    // If the token exists, add it to the authorization header
    if (authToken) {
      config.headers['Authorization'] = `Bearer ${authToken}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    const status = error.response ? error.response.status : null;

    if (status === 401 || status === 403) {
      // Redirect to login page or handle unauthorized access
      window.location.href = PUBLIC_ROUTES.ERROR_401;
    }

    return Promise.reject(error);
  }
);


export default axiosInstance