import { useDispatch, useSelector } from "react-redux";
import { allPermissions, currentRoleSelector, logoutUser, selectCurrentUser, setModeType } from "../features/auth.slice";
import { saveAuth, removeAuth, isAuthenticated } from "../AuthHelper";
import { IModeType } from "../../../pages/users/models/Users.interfaces";


const useAuth = () => {
    const currentUser = useSelector(selectCurrentUser)
    const currentRole = useSelector(currentRoleSelector)
    const permissions = useSelector(allPermissions)
    const isForceChangePassword = currentUser?.force_password_change === 1
    const dispatch = useDispatch();
    const logout = () => {
        dispatch(logoutUser())
    }
    const _isAuthenticated = () => {
        return currentUser?.token || isAuthenticated()
    }
    const changeModeType = ({ mode }: { mode: IModeType }) => {
        dispatch(setModeType({ mode }))
    }

    return { logout, currentUser, saveAuth, removeAuth, currentRole, currentMode: currentUser?.mode, isAuthenticated: _isAuthenticated, permissions, isForceChangePassword, changeModeType }
}

export default useAuth