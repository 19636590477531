import { FC, memo, useMemo } from 'react'
import { useLang } from './Metronici18n'
import { IntlProvider } from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/hi'

import enMessages from './messages/en'
import hiMessages from './messages/hi'
import { WithChildren } from '../helpers'

const I18n: FC<WithChildren> = ({ children }) => {
  const locale = useLang()

  const mergedMessages = useMemo(() => {
    const allMessages: { [x: string]: Record<string, string> } = {
      en: enMessages,
      hi: hiMessages,
    }
    const messages: Record<string, string> = allMessages[locale]
    return { ...enMessages, ...messages };  // Fallback mechanism
  }, [locale]);  // Only recalculate when locale changes

  return (
    <IntlProvider locale={locale} messages={mergedMessages}>
      {children}
    </IntlProvider>
  )
}

const I18nProvider = memo(I18n)
export { I18nProvider }
