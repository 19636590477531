
import { useEffect } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { KTCard, toAbsoluteUrl } from '../../../_metronic/helpers'
// import { StyledBackground } from './components/StyledBackground'
import useAuth from './hooks/useAuth'
import { useDispatch } from 'react-redux'
import { logoutUser } from './features/auth.slice'
import { useIntl } from 'react-intl'

const AuthLayout = () => {
  const { currentUser } = useAuth();
  const intl = useIntl()
  const appName = import.meta.env.VITE_APP_NAME?.length ? intl.$t({ id: import.meta.env.VITE_APP_NAME }) : null
  const appDescription = import.meta.env.VITE_APP_DESCRIPTION?.length ? intl.$t({ id: import.meta.env.VITE_APP_DESCRIPTION }) : null
  const dispatch = useDispatch()
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  useEffect(() => {
    if (currentUser?.user_id) {
      dispatch(logoutUser())
    }
  }, [currentUser, dispatch])

  return (
    // <StyledBackground>
    <div className='d-flex flex-center flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-center justify-content-center w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <KTCard className='d-flex flex-lg-center flex-column w-lg-500px"'>
          <div >
            {/* begin::Wrapper */}
            <div className="d-flex flex-center flex-column">
              <Link to='/' className='my-2'>
                <img alt='Logo' style={{ height: 70 }} src={toAbsoluteUrl(import.meta.env.VITE_APP_LOGO_AUTH)} />
              </Link>
              {appName && <h1 className=' fs-2qx fw-bolder text-secondary text-center mb-7'>
                {appName}
              </h1>}
              {appDescription && <div className='fs-base text-center'>
                {appDescription}

              </div>}
            </div>
            <div className='w-lg-600px p-10'>
              <Outlet />
            </div>
            {/* end::Wrapper */}
          </div>
        </KTCard>
        {/* end::Form */}

        {/* begin::Footer */}
        {/* <div className='d-flex flex-center flex-wrap px-5'>
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='#' className='px-5' target='_blank'>
              Terms
            </a>

            <a href='#' className='px-5' target='_blank'>
              Plans
            </a>

            <a href='#' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div>
        </div> */}
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}

      {/* end::Aside */}
    </div>
    // </StyledBackground>

  )
}

export { AuthLayout }
